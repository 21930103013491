import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { GID_ATTR, HIGHLIGHT_CLASS, NODE_TYPE, PENDING_COMMENT_HIGHLIGHT_CLASS, POSTED_COMMENT_HIGHLIGHT_CLASS } from '@core/constants';
import { ISerializedHighlight } from '@core/interfaces/review.interface';
import * as _ from 'lodash';
import { HighlightDomService } from './highlight-dom.service';
@Injectable({
  providedIn: 'root',
})
export class HighlightSerializeService {
  private renderer2: Renderer2;

  constructor(private rendererFactory: RendererFactory2, private highlightDomService: HighlightDomService) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }


  /**
   * Find the position of a comment in step
   * @param containerElement 
   * @param gid 
   * @returns 
   */
  public serializeChunks(containerElement: HTMLElement | null, gid: string[]): ISerializedHighlight[] {
    const highlights = this.getHighlights(containerElement);
    if (!highlights) {
      return;
    }
    const hlDescriptors: ISerializedHighlight[] = [];
    highlights
      .filter((highlight: HTMLElement) => !!highlight)
      .filter((highlight: HTMLElement) => gid.indexOf(highlight.getAttribute(GID_ATTR)) > -1)
      .forEach((highlight: HTMLElement) => {
        const highlightPreviousSibling = highlight.previousSibling;
        const hl: ISerializedHighlight = {
          textContent: highlight.textContent,
          path: this.getElementPath(containerElement, highlight),
          offset: highlightPreviousSibling && highlightPreviousSibling.nodeType === NODE_TYPE.TEXT_NODE
            && highlightPreviousSibling instanceof Text ? highlightPreviousSibling.length : 0,
          gid: highlight.getAttribute(GID_ATTR)
        };

        hlDescriptors.push(hl);
      });
    return hlDescriptors;
  }

  /**
   * Returns a span element (comment wraper) with a specific gid
   * @param containerElement 
   * @param gid 
   * @returns 
   */
  public getHighlightByGid(containerElement: HTMLElement, gid: string): HTMLElement {
    if (containerElement) {
      const highlights: HTMLElement[] = Array.from(containerElement.querySelectorAll(`.${HIGHLIGHT_CLASS}`));
      return highlights.find(highlight => highlight.getAttribute(GID_ATTR) === gid);
    }
    return null;
  }

  /**
   * Will "deselect" some html elements (remove css classes)
   * @param el 
   * @returns 
   */
  private unwrap(el): HTMLElement[] {
    const nodes: HTMLElement[] = Array.from(el?.childNodes || []);
    nodes.forEach((node) => {
      const wrapper = node.parentNode;
      if (node && wrapper && wrapper.parentNode) {
        this.renderer2.insertBefore(wrapper.parentNode, node, wrapper);
      }
      if (!wrapper.textContent) {
        this.remove(wrapper);
      }
    });
    return nodes;
  }

  /**
   * Utils, merges texts after unwrap
   * @param textNode 
   */
  private mergeSiblingTextNodes(textNode: {
    previousSibling: any;
    nextSibling: any;
    nodeValue: any;
  }): void {

    if (textNode && textNode.nodeValue && textNode.nodeValue.nodeType !== NODE_TYPE.ELEMENT_NODE) {
      const prev = textNode.previousSibling;
      const next = textNode.nextSibling;

      if (prev && prev.nodeType === NODE_TYPE.TEXT_NODE) {
        textNode.nodeValue = prev.nodeValue + textNode.nodeValue;
        this.remove(prev);
      }
      if (next && next.nodeType === NODE_TYPE.TEXT_NODE) {
        textNode.nodeValue = textNode.nodeValue + next.nodeValue;
        this.remove(next);
      }
    }
  }


  /**
   * Removes span wrapper from text
   * @param highlight 
   */
  public removeTextHighlight(highlight: HTMLElement) {
    const textNodes: HTMLElement[] = this.unwrap(highlight);
    if (textNodes) {
      textNodes.forEach(node => {
        this.mergeSiblingTextNodes(node);
      });
    }
  }

  /**
   * Removes highlight effect from table cell
   * @param highlight 
   */
  public removeCellHighlight(highlight: HTMLElement) {
    if (highlight) {
      this.renderer2.removeAttribute(highlight, GID_ATTR);
      this.renderer2.removeClass(highlight, HIGHLIGHT_CLASS);
    }
  }


  /**
   * Utils, Adds css class
   * @param highlight 
   * @param className 
   */
  public addClass(highlight: HTMLElement, className: string): void {
    if (className === POSTED_COMMENT_HIGHLIGHT_CLASS) {
      this.renderer2.removeClass(highlight, PENDING_COMMENT_HIGHLIGHT_CLASS);
    }
    this.renderer2.addClass(highlight, className);
  }

  /**
   * Get all span elements that are highlights (contains a comment)
   * @param reviewTarget 
   * @returns 
   */
  public getHighlights(reviewTarget: HTMLElement): HTMLElement[] {
    return Array.from(reviewTarget.querySelectorAll('[' + GID_ATTR + ']'));
  }

  /**
   * Returns the position of elment in containerElement
   * @param containerElement 
   * @param el 
   * @returns 
   */
  public getElementPath(containerElement: HTMLElement, el: HTMLElement | ParentNode | ChildNode): number[] {
    const path: number[] = [];
    let childNodes;
    do {
      if (el instanceof HTMLElement && el.parentNode) {
        const allChildren = Array.from(el.parentNode.childNodes);
        path.unshift(allChildren.indexOf(el));
        el = el.parentNode;
      }
    } while (el !== containerElement || !el);
    return path;
  }

  /**
   * Highlights in a container a specific text based on hlDescriptor
   * @param containerElement 
   * @param hlDescriptor 
   * @param isCellHighlight 
   * @returns 
   */
  deserialize(containerElement: HTMLElement, hlDescriptor: ISerializedHighlight, isCellHighlight?: boolean): HTMLElement {
    if (this.getHighlightByGid(containerElement, hlDescriptor.gid)) {
      return;
    }
    try {
      const highlight = _.cloneDeep(hlDescriptor);
      if (!highlight.path || highlight.path.length === 0) {
        return;
      }
      let elIndex = Math.max(0, highlight.path.pop());

      // should have a value and not be 0 (false is = to 0)
      if (elIndex !== 0 && !elIndex) {
        return;
      }

      let node: Node | Text = containerElement as Node;
      while (highlight.path.length > 0) {
        const idx = highlight.path.shift();
        if (idx > -1) {
          node = node.childNodes[idx] as Node;
        }
      }
      if (node.childNodes[elIndex - 1] && (node.childNodes[elIndex - 1].nodeType === NODE_TYPE.TEXT_NODE ||
        (node.childNodes[elIndex - 1] as HTMLElement).classList.contains('cell-highlight'))) {
        elIndex = Math.max(0, elIndex - 1);
      }
      node = node.childNodes[elIndex] as Text;
      const isAnchorTag = node?.nodeType === NODE_TYPE.ELEMENT_NODE
        && this.highlightDomService.isAnchorTag(node)
        && node?.childNodes[0] instanceof Text;

      node = isAnchorTag ? node?.childNodes[0] : node;
      if (node instanceof Text) {
        const hlNode = node.splitText(highlight.offset);
        hlNode.splitText(highlight.textContent.length);
        if (hlNode.nextSibling && !hlNode.nextSibling.nodeValue) {
          this.remove(hlNode.nextSibling);
        }
        if (hlNode.previousSibling && !hlNode.previousSibling.nodeValue) {
          this.remove(hlNode.previousSibling);
        }
        if (highlight) {
          return this.highlightDomService.wrap(hlNode, highlight.gid);
        }
      } else if (isCellHighlight) {
        return this.highlightDomService.wrapCell(node, highlight.gid);
      }
    } catch (e) {
      if (console && console.error) {
        console.error(`Can't deserialize highlight descriptor. Cause: ${e}`);
      }
    }
  }

  /**
   * Utils function user when highlighting
   * @param el 
   */
  private remove(el): void {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
      el = null;
    }
  }
}
