import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccessLevel } from '@core/constants';
import { INavMenuItem, NotificationResponse } from '@core/interfaces';
import { Company, IUser } from '@core/models';
import { CompaniesService, HelperService, RoutingService } from '@core/services';
import { HubSpotService } from '@core/services/hub-spot.service';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Select, Store } from '@ngxs/store';
import { AppSelectors } from '@store/app.selectors';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ToggleMenu } from 'src/app/store/app.actions';
import { CompanySelectors } from 'src/app/store/company/company.selectors';
import { ResetNotificationsCounter } from 'src/app/store/notification/notification.actions';
import { NotificationSelectors } from 'src/app/store/notification/notification.selectors';
import { Logout } from 'src/app/store/user/user.actions';
import { UserSelectors } from 'src/app/store/user/user.selectors';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class MainNavComponent {
  @Select(NotificationSelectors.getNotifications)
  notifications$: Observable<NotificationResponse[]>;

  @Select(NotificationSelectors.getTotalUnseen)
  totalUnseen$: Observable<number>;

  @Select(UserSelectors.isBTRAdmin)
  isBTRAdmin$: Observable<boolean>;

  isMenuOpened = false;
  currentUser: IUser;
  company: Company;
  currentRoute: string;
  navMenu: INavMenuItem[] = [];
  readonly AccessLevel = AccessLevel;
  private navigationEvents$: Subscription;
  private isMenuOpened$: Subscription;
  private company$: Subscription;
  private currentUser$: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    public router: Router,
    public companiesService: CompaniesService,
    public routingService: RoutingService,
    private store: Store,
    private helperService: HelperService,
    private hubSpotService: HubSpotService
  ) {
    this.isMenuOpened$ = this.store.select(AppSelectors.isMenuOpened).subscribe(isOpened => {
      this.isMenuOpened = isOpened;
    });

    this.company$ = this.store.select(CompanySelectors.getCurrentCompany)
      .subscribe(company => {
      this.company = company;
      this.generateNavMenu();
      this.cdr.markForCheck();
    });

    this.currentUser$ = this.store.select(UserSelectors.getCurrentUser).pipe(
      filter(user => !!user)
    ).subscribe(user => {
      this.currentUser = user;
      this.generateNavMenu();
      this.cdr.markForCheck();
    });

    this.navigationEvents$ = this.store.select(AppSelectors.getCurrentRoute).subscribe(route => {
      this.currentRoute = route;
      this.generateNavMenu();
      this.cdr.markForCheck();
    });
  }

  onLogout() {
    this.store.dispatch(new Logout());
  }

  goToProfile() {
    this.router.navigate([this.routingService.PROFILE_BASIC_INFORMATION.url()]);
  }

  toggleMenu() {
    this.store.dispatch(new ToggleMenu());
  }

  resetCounter() {
    this.store.dispatch(new ResetNotificationsCounter());
  }

  toggleHubSpot(){
    this.hubSpotService.toggleWidgetVisibility();
  }

  hideHubSpot(){
    this.hubSpotService.hideWidget();
  }

  private generateNavMenu() {
    if (this.currentUser && (this.company || this.store.selectSnapshot(UserSelectors.isBTRAdmin))) {
      this.navMenu = this.helperService.generateNavMenu(
        this.store.selectSnapshot(UserSelectors.getUserPermissions),
        this.store.selectSnapshot(UserSelectors.isBTRAdmin),
        this.company,
        this.currentRoute
      );
    }
  }
}
