import { Injectable } from '@angular/core';
import { ITask } from '@core/interfaces';
import { TasksService } from '@core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { GetTasks, MarkTaskAsSeen, StartTasksCheck, StopTasksCheck } from './tasks.actions';

export interface TasksStateModel {
  tasks: ITask[];
  isInitialized: boolean;
}

@State<TasksStateModel>({
  name: 'TasksState',
  defaults: {
    tasks: [],
    isInitialized: false
  }
})
@Injectable()
export class TasksState {
  constructor(private tasksService: TasksService) { }

  @Action(GetTasks)
  getTasks({ patchState }: StateContext<TasksStateModel>, { offset, limit }: GetTasks) {
    return this.tasksService.getTasks(offset, limit).pipe(
      map(tasks => tasks.data),
      tap(tasks => patchState({ tasks, isInitialized: true }))
    );
  }

  @Action(StartTasksCheck, { cancelUncompleted: true })
  startTasksCheck({ patchState }: StateContext<TasksStateModel>, { offset, limit }: StartTasksCheck) {
    return this.tasksService.startTasksCheck(offset, limit).pipe(
      map(tasks => tasks.data),
      tap(tasks => patchState({ tasks, isInitialized: true }))
    );
  }

  @Action(StopTasksCheck, { cancelUncompleted: true })
  stopTasksCheck() {
    return this.tasksService.stopTasksCheck();
  }

  @Action(MarkTaskAsSeen, { cancelUncompleted: true })
  markTaskAsSeen({ dispatch }: StateContext<TasksStateModel>, { uuid }: MarkTaskAsSeen) {
    return this.tasksService.markTaskAsSeen(uuid).pipe(
      tap(() => dispatch(new GetTasks(0, Number.MAX_SAFE_INTEGER)))
    );
  }
}
