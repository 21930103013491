<div class="home-container flex-row flex-wrap" *ngIf="currentUser">

  <ng-container *appUserAccessLevel="[ AccessLevel.BTRAdmin ]">
    <div class="home-admin-wrapper padding-15 flex-row flex-wrap flex-100">
      <div class="welcome-header align-center flex-row flex-wrap flex-100">
        <ng-container *ngIf="currentUser.first_name">
          <img class="profile-pic" *ngIf="currentUser.image_url" src="{{currentUser.image_url}}"/>
          <img class="profile-pic" *ngIf="!currentUser.image_url" src="../../../../assets/img/profile-default.png"/>
          <span class="welcome margin-left-10">Welcome to Res_Q Admin, {{currentUser | displayName}}</span>
        </ng-container>
      </div>
      <div class="flex-row flex-wrap flex-100" *appUserAccessLevel="[ AccessLevel.User ]">
        <div class="flex-100 justify-center items-center">
          <img *ngIf="company && company.image_url" src="{{company.image_url}}"/>
          <img *ngIf="company && !company.image_url" src="../../../../assets/img/favicon.ico"/>
        </div>
      </div>

      <div class="flex-row flex-wrap flex-100 justify-center items-center">
        <app-dashboard class="flex-100"></app-dashboard>
      </div>
    </div>
  </ng-container>


  <ng-container *appUserAccessLevel="[AccessLevel.User]">
    <div class="home-header flex-100">
      <span class="icon icon-n-home"></span>
      <span class="home-text subtitle-1">Homepage</span>
    </div>
    <div class="top-background">
    </div>
    <div class="user-greeting flex-row flex-wrap flex-100 justify-between items-center">
      <div class="left-info flex-col flex-70">
        <div class="welcome-text headline-3 tst-welcome-container">Hello {{currentUser | displayName}}</div>
        <div class="body-3 bold-text">Here’s an overview of your account</div>
      </div>
    </div>

    <ng-container *ngIf="permissions">
      <div class="tasks-container flex-100">
        <div class="tasks-items flex-row flex-wrap flex-100 justify-between">
          <ng-container *ngFor="let taskType of taskTypes">
            <app-task-card class="flex-row"
                           [tasks]="taskType"
                           [style.max-width]="(96 / taskTypes.length) + '%'">
            </app-task-card>
          </ng-container>
        </div>
      </div>
      <div class="home-workflows-container flex-row flex-wrap flex-100" *ngIf="permissions.viewWorkspaceRuntimeListing">
        <div class="home-workflows-header flex-row flex-wrap flex-100 justify-between items-center">
          <div class="home-workflows-label headline-4 flex-70">Your Workflows</div>
          <div class="home-workflows-link-wrapper justify-end items-center flex-30">
            <div (click)="goToWorkflows()" class="view-all-workflows-link body-link">
              <span>View All</span>
              <span class="arrow-icon"><mat-icon class="right-arrow">arrow_forward</mat-icon></span>
            </div>
          </div>
        </div>
        <div class="home-workflow-items flex-row flex-wrap flex-100 justify-start items-center flex-gap-20"
             *ngIf="workflows && !workflowsLoading">
          <div *ngFor="let workflow of workflows"
              class="home-workflow-item-container">
            <app-workflow-card class="flex-100" [company]="company" [workflow]="workflow"></app-workflow-card>
          </div>
        </div>
        <div class="home-workflow-items flex-row flex-wrap flex-100 justify-start items-center flex-gap-20"
             *ngIf="!workflowsLoading && !workflows?.length">
          <div class="home-workflow-item-container">
            <app-workflow-create-card></app-workflow-create-card>
          </div>
        </div>
      </div>
      <div class="systems-container flex-row flex-wrap flex-100" *ngIf="systems">
        <div class="systems-label headline-4 flex-100">
          Your Systems
        </div>
        <div class="system-items flex-row flex-wrap flex-100 justify-start items-center flex-gap-10">
          <ng-container *ngFor="let system of systems">
            <div class="system-item-container">
              <app-system-card class="flex-100" [system]="system"></app-system-card>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
