import { Clipboard } from '@angular/cdk/clipboard';
import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { PermissionList, Permissions } from '@core/constants/permissions.enum';
import { DateFormatPipe } from '@core/pipes';
import { ImpactAssessmentService } from '@core/services/impact-assessment.service';
import { RoutingService } from '@core/services/routing.service';
import { Store } from '@ngxs/store';
import { TraceMatrixLinksService } from '@shared/components/trace-matrix-links/trace-matrix-links.service';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import * as CanvasActions from 'src/app/canvas/store/canvas.actions';
import * as DocumentActions from 'src/app/documents/store/document.actions';
import { ActiveFilter } from '@core/interfaces/filter.interface';
import { environment } from '../../../environments/environment';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import { TableFeTypes } from '../../canvas/shared/fe-components/table/table-fe-types.enum';
import { AccessLevel, SDOX_DOCUMENT_CATEGORIES } from '../constants';
import {
  IApiResponseMultipleErrors,
  IApiResponseSingleError,
  ICanvasConfigData,
  ICanvasFunctionalElement,
  ICanvasStepData, ICanvasStepDataRow,
  ICanvasTableColumn,
  IFailType,
  IFilterSelectValue,
  IImpactAssessmentQuestionFullWithProgress,
  INavMenuItem,
  IQuestionsResult,
  ITableLink,
  ITask, IUserData, IWorkflowRuntime,
  IWorkflowType, LinksList,
  NotificationLink,
  SdoxDocumentCategory, TemplateAccessRequestType,
  TemplateType, UserGroupsResource, WorkflowAccessListResponse
} from '../interfaces';
import { Company, IUser, IUserLite } from '../models';
import { AclService } from './acl.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {

  constructor(
    private toastr: ToastrService,
    private routingService: RoutingService,
    private router: Router,
    private traceMatrixLinksService: TraceMatrixLinksService,
    private clipboard: Clipboard,
    private aclService: AclService,
    private store: Store,
    private dateFormat: DateFormatPipe
  ) {
  }

  /*
  *Example:
  *Romania is UTC +02:00 , meaning that 00:00 in Romania is 22:00 UTC
  *For 14.01.2022 → 14.01.2022 in Romania FD Sends 2022-01-13T22:00:00Z → 2022-01-14T21:59:59Z
  *For 14.01.2022 → 15.01.2022 in Romania FD Sends 2022-01-13T22:00:00Z → 2022-01-15T21:59:59Z
  */
  static formatDateToCompanyTimezoneUTC(company: Company, date: string, isStartDate = true) {
    // Get timezone hour then subtract it below (Example: 'from 00:00:00 - timezoneHour' and 'to 23:59:59 - timezoneHour').
    const timezoneRetrievalDate = moment(new Date(date)).tz(company.uuid ? company.config.timezone : moment.tz.guess()).format();
    // Parse the '+03' or '-03' timezone string to integer.
    const timezoneHour = timezoneRetrievalDate.slice(timezoneRetrievalDate.length - 1) !== 'Z' ?
      parseInt(timezoneRetrievalDate.slice(timezoneRetrievalDate.length - 6, timezoneRetrievalDate.length - 3), 10) : 0;
    if (isStartDate) {
      return moment(date).utc().subtract(timezoneHour, 'hours').format();
    } else {
      return moment(date).utc().set('hour', 23).set('minute', 59).set('seconds', 59).subtract(timezoneHour, 'hours').format();
    }
  }

  showErrorMessage(
    err: any,
    toastTitle?: string,
    toastClass?: string,
    toastMessageClass?: string
  ) {
    if (!navigator.onLine) {
      return;
    }
    let errMsg = this.getErrorMessage(err);
    if (!err.error) {
      this.toastr.error(errMsg);
    } else if (!Array.isArray(err.error.errors)) {
      // When the API has an issue it responds with the stack trace
      if (!err.error.errors) {
        if (!(err.error instanceof ArrayBuffer)) {
          return this.toastr.error(errMsg);
        }
      }
      const error = err.error as IApiResponseMultipleErrors;
      if (err.status === HttpStatusCode.Forbidden) {
        errMsg += '<div>Go back to the <a href="/home">Home</a> page</div>';
      }

      this.toastr.error(errMsg as string, toastTitle || error.message, {
        tapToDismiss: false,
        enableHtml: true,
        closeButton: true,
        timeOut: 10000, // 10 seconds
        extendedTimeOut: 10000, // 10 seconds
        toastClass: toastClass || 'ngx-toastr',
        messageClass: toastMessageClass || ''
      });
    } else {
      this.toastr.error(errMsg);
    }
  }

  getErrorMessage(err: any) {
    if (!err.error) {
      return err;
    }
    if (typeof err.error === 'string') {
      err.error = JSON.parse(err.error);
    }
    if (!Array.isArray(err.error.errors)) {
      if (!err.error.errors) {
        if (!(err.error instanceof ArrayBuffer)) {
          return 'Server error';
        }
      }
      let error = err.error as IApiResponseMultipleErrors;
      if (error instanceof ArrayBuffer) {
        const decodedString = String.fromCharCode.apply(null, new Uint8Array(error));
        error = JSON.parse(decodedString);
      }
      let msg = '';
      let pos = 1;
      for (const [key, value] of Object.entries(error.errors)) {
        msg += '<div>' + pos + '. ' + (Array.isArray(value) ? value.join(', ') : value) + '</div>';
        pos++;
      }
      return msg;
    } else {
      return (err.error as IApiResponseSingleError).message;
    }
  }

  formatDateToCompanyFormat(date: string, company?: Company, fullDate?: boolean) {
    if (!date) {
      return '-';
    }
    if (company) {
      return moment(date).tz(company.config.timezone).format(this.getCompanyDateFormat(company, fullDate));
    } else {
      return fullDate ? moment(date).format('MM.DD.YYYY HH:MM:ss A') : moment(date).format('MM.DD.YYYY');
    }
  }

  getBrowser(): string {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getCompanyDateFormat(company: Company, fullDate?: boolean) {
    return `${company.config.dateformat} ${company.config.timeformat}`;
  }

  /*
  * Get all characters after the last occurrence of a character
  * Ex: Get text after last space, comma etc.
  */
  getCharsAfterLastOccurrence(text: string, char: string): string {
    return text.split(char).pop().trim();
  }

  /*
  * Get all characters before the last occurrence of a character
  * Ex: Get text before last space, comma etc.
  */
  getCharsBeforeLastOccurrence(text: string, char: string): string {
    return text.substring(0, text.lastIndexOf(char));
  }

  pad(size, length) {
    let str = '' + size;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  /*
  * Update rows indexes when we move/delete/add row
  * */
  setRowsFields(
    step_data: ICanvasStepData,
    config_data: ICanvasConfigData,
    rows: ICanvasStepDataRow[],
    {
      fromIndex = 0,
      toIndex,
      updateRequirementId = false,
      updateOrderIndex = false,
    }: {
      fromIndex?: number;
      toIndex?: number;
      updateRequirementId?: boolean;
      updateOrderIndex?: boolean;
    } = {}
  ) {
    if (!step_data || !config_data || !rows?.length) {
      return;
    }
    const _toIndex = typeof toIndex !== 'undefined' ? toIndex : rows.length;
    const length = Math.max(3, (rows.length).toString().length);
    for (let i = fromIndex; i < _toIndex; i++) {
      if (rows[i]) {
        if (updateRequirementId || !rows[i].uuid) {
          rows[i][step_data.columns[0].key] = config_data.sequence_prefix + `-${this.pad(i + 1, length)}`;
        }

        if (updateOrderIndex || !rows[i].uuid) {
          rows[i].order_index = i;
        }
      }
    }
  }

  /*
  * Returns the link name depending if it is an inbound or outbound link
  * */
  getLinkDisplayName(link: ITableLink, fromRowUuid: string): string {
    let requirementId = link.requirementId;
    let sequence;

    if (link.from_row_uuid === fromRowUuid) {
      sequence = link.to_sequence_prefix ? `${link.to_sequence_prefix}-` : ``;
      requirementId = requirementId || `${sequence}${this.pad(link.to_row_order_index + 1, 3)}`;
      return `${link.to_workflow_name} V${link.to_workflow_version} / ${link.to_table_name} / ${requirementId}`;
    } else {
      sequence = link.from_sequence_prefix ? `${link.from_sequence_prefix}-` : ``;
      requirementId = requirementId || `${sequence}${this.pad(link.from_row_order_index + 1, 3)}`;
      return `${link.from_workflow_name} V${link.from_workflow_version} / ${link.from_table_name} / ${requirementId}`;
    }
  }

  /*
  * Check if a variable is type object
  * */
  isObjectType(obj: any): boolean {
    return !!(obj && typeof obj === 'object' && obj !== null && !Array.isArray(obj));
  }

  /*
  * Filters FEs by document category. Each template has access to several FEs (see SDOX_DOCUMENT_CATEGORIES)
  * */
  filterFeByDocumentCategory(fes: ICanvasFunctionalElement[], category: SdoxDocumentCategory): ICanvasFunctionalElement[] {
    // FEs that need to be checked before adding on canvas menu/document section library
    const fesTypes = [
      CanvasFunctionalElementType.Table,
      CanvasFunctionalElementType.External,
      CanvasFunctionalElementType.General_Table,
      CanvasFunctionalElementType.Test_Script,
      CanvasFunctionalElementType.SectionTitle
    ]; // FEs that need to be checked

    // Get filtered list of fes based on SDOX document type
    const allowedTypes: CanvasFunctionalElementType[] = SDOX_DOCUMENT_CATEGORIES.find(_category => category === _category.value)?.feTypes;

    // If we don't have a category set or invalid category, we are showing all FEs
    // (useful if the API doesn't return the "sdox_document_category" key)
    if (!category || !allowedTypes) {
      return fes;
    }
    // Get allowed FEs by document category
    return fes.filter(fe => {
      // If the FE is one of the FEs that needs to be checked
      // we check if the FE belongs to the allowed types
      if (fesTypes.includes(fe.fe_type as CanvasFunctionalElementType)) {
        return allowedTypes.includes(fe.fe_type as CanvasFunctionalElementType);
      } else {
        return true;
      }
    });
  }

  filterFeByTemplateType(fes: ICanvasFunctionalElement[], type: TemplateType): ICanvasFunctionalElement[] {
    const allowedFEByTemplateType = {
      [TemplateType.ILM]: [
        CanvasFunctionalElementType.DrawConnector,
        CanvasFunctionalElementType.Initiation,
        CanvasFunctionalElementType.Switch,
        CanvasFunctionalElementType.Impact,
        CanvasFunctionalElementType.Implementation,
        CanvasFunctionalElementType.Completer,
        CanvasFunctionalElementType.Summary
      ],
      [TemplateType.ECM]: [
        CanvasFunctionalElementType.DrawConnector,
        CanvasFunctionalElementType.Initiation,
        CanvasFunctionalElementType.Switch,
        CanvasFunctionalElementType.Impact,
        CanvasFunctionalElementType.Implementation,
        CanvasFunctionalElementType.Completer,
        CanvasFunctionalElementType.Summary
      ],
      [TemplateType.SDOX]: [
        CanvasFunctionalElementType.DrawConnector,
        CanvasFunctionalElementType.Completer,
        CanvasFunctionalElementType.Table,
        CanvasFunctionalElementType.General_Table,
        CanvasFunctionalElementType.Test_Script,
        CanvasFunctionalElementType.External,
        CanvasFunctionalElementType.SectionTitle
      ]
    };

    return fes.filter(fe => {
      return allowedFEByTemplateType[type].includes(fe.fe_type as CanvasFunctionalElementType);
    });
  }

  /*
  * Sort links by display name
  * */
  sortLinks(links: ITableLink[], rowUuid: string): ITableLink[] {
    return links.map(link => {
      link.displayName = this.getLinkDisplayName(link, rowUuid);
      return link;
    }).sort((a, b) => (a.displayName > b.displayName) ? 1 : -1); // sort ascending
  }

  /*
  * Order should be: impactAssessment, links, test_result_link, attachment
  * */
  orderColumns(columns: ICanvasTableColumn[]): ICanvasTableColumn[] {
    const columnsWithoutFixedCols = columns.filter(col =>
      !['links', 'impactAssessment', 'test_result_link', 'attachment'].includes(col.key));

    const linkColumn = columns.find(col => col.key === 'links');
    const impactAssessmentColumn = columns.find(col => col.key === 'impactAssessment');
    const testResultLinkColumn = columns.find(col => col.key === 'test_result_link');
    const attachmentColumn = columns.find(col => col.key === 'attachment');

    if (impactAssessmentColumn) {
      columnsWithoutFixedCols.push(impactAssessmentColumn);
    }

    if (attachmentColumn) {
      columnsWithoutFixedCols.push(attachmentColumn);
    }

    if (linkColumn) {
      columnsWithoutFixedCols.push(linkColumn);
    }

    if (testResultLinkColumn) {
      columnsWithoutFixedCols.push(testResultLinkColumn);
    }

    return [...columnsWithoutFixedCols];
  }

  /*
  * Checks if string ends with a value that belongs to a collection
  * */
  collectionIncludeInput(input: string, collection: string[]): boolean {
    return collection.findIndex(element => input.endsWith(element)) > -1;
  }

  hasUnansweredQuestions(questions: IImpactAssessmentQuestionFullWithProgress[]): boolean {
    let hasUnansweredQuestions = false;
    questions.forEach(question => {
      let wasAnswered = false;
      question.answers.forEach(answer => {
        if (answer.is_checked) {
          wasAnswered = true;
        }
        if (answer.is_checked && answer.trigger?.questions?.length) {
          wasAnswered = wasAnswered && !this.hasUnansweredQuestions(answer.trigger.questions);
        }
      });
      if (!wasAnswered) {
        hasUnansweredQuestions = true;
      }
    });
    return hasUnansweredQuestions;
  }

  answeredQuestions(questions: IImpactAssessmentQuestionFullWithProgress[]): number {
    let answersChecked = 0;
    questions.forEach(question => {
      let wasAnswered = false;
      question.answers.forEach(answer => {
        if (answer.is_checked) {
          wasAnswered = true;
        }
        if (answer.is_checked && answer.trigger?.questions?.length) {
          wasAnswered = wasAnswered && !this.hasUnansweredQuestions(answer.trigger.questions);
        }
      });
      if (wasAnswered) {
        answersChecked++;
      }
    });
    return answersChecked;
  }

  getAnswersFromQuestions(questions: IImpactAssessmentQuestionFullWithProgress[]): IQuestionsResult[] {
    let answers: IQuestionsResult[] = [];
    questions.forEach(question => {
      question.answers.forEach(answer => {
        if (answer.is_checked) {
          answers.push({
            question_uuid: answer.question_uuid,
            answer_uuid: answer.uuid
          });
        }
        if (answer.is_checked && answer.trigger?.questions?.length) {
          answers = [...answers, ...this.getAnswersFromQuestions(answer.trigger.questions)];
        }
      });
    });
    return answers;
  }


  public getWorkflowUrlFromTask(task: ITask) {
    if (task.original_workflow_uuid) {
      return this.routingService.DOCUMENTS_REVIEW_INFO.url([
        task.workflow_uuid,
        task.workflow_version_uuid,
        task.original_workflow_uuid,
        task.original_version_uuid
      ]);
    } else {
      return this.getWorkflowUrl(task.workflow_type, task.workflow_uuid, task.workflow_version_uuid);
    }
  }

  public getWorkflowUrlFromNotification(notification: NotificationLink, isTemplate: boolean, stepUuid?: string) {
    if (notification.parameters.review_version_uuid) {
      return this.routingService.DOCUMENTS_REVIEW_INFO.url([
        notification.parameters.review_workflow_uuid,
        notification.parameters.review_version_uuid,
        notification.parameters.original_workflow_uuid,
        notification.parameters.original_version_uuid
      ]);
    } else {
      if (isTemplate) {
        return this.getTemplateUrl(
          notification.parameters.original_workflow_uuid,
          notification.parameters.original_version_uuid,
          stepUuid
        );
      } else {
        return this.getWorkflowUrl(
          notification.workflow_type,
          notification.parameters.original_workflow_uuid,
          notification.parameters.original_version_uuid,
          stepUuid
        );
      }
    }
  }

  public getStepUrlFromNotification(notification: NotificationLink, isTemplate: boolean, stepUuid: string) {
    if (isTemplate) {
      return this.getTemplateUrl(
        notification.parameters.original_workflow_uuid,
        notification.parameters.original_version_uuid,
        stepUuid
      );
    } else {
      return this.getWorkflowUrl(
        notification.workflow_type,
        notification.parameters.original_workflow_uuid,
        notification.parameters.original_version_uuid,
        stepUuid
      );
    }
  }

  getWorkflowUrl(workflowType: string, workflowUUID: string, versionUUID: string, stepUuid?: string) {
    let url = '';
    if (workflowType === TemplateType.SDOX) {
      url = this.routingService.DOCUMENTS_DOCUMENT_INFO.url([workflowUUID, versionUUID]);
    } else {
      url = this.routingService.WORKFLOWS_WORKFLOW_INFO.url([workflowUUID, versionUUID]);
    }
    return stepUuid ? `${url}#${stepUuid}` : url;
  }

  getTemplateUrl(workflowUUID: string, versionUUID: string, stepUuid?: string) {
    const url = this.routingService.TEMPLATES_TEMPLATE_INFO.url([workflowUUID, versionUUID]);
    return stepUuid ? `${url}#${stepUuid}` : url;
  }

  requireMatchCompany(control: UntypedFormControl): ValidationErrors | null {
    const selection = control.value;

    if (selection) {
      const invalidValue = (this as any).options.length && typeof selection === 'string' &&
        (this as any).options.map(c => c.name).indexOf(selection) < 0;

      if (!(this as any).options.length || invalidValue) {
        return { requireMatch: true };
      }
    }
    return null;
  }

  base64ToBlob(data: string, fileName: string): Promise<File> {
    return fetch(data)
      .then(res => res.blob())
      .then(blob => {
        return new File([blob], fileName, { type: 'image/png' });
      });
  }

  generateFormDataForCompany(companyData): FormData {
    const toFormData = (f => f(f))(h => f => f(x => h(h)(f)(x)))(f => fd => pk => d => {
      if (d instanceof Object) {
        Object.keys(d).forEach(k => {
          const v = d[k];
          if (pk) { k = `${pk}[${k}]`; }
          if (v instanceof Object && !(v instanceof Date) && !(v instanceof File)) {
            return f(fd)(k)(v);
          } else {
            if (typeof v !== 'undefined') {
              if (v == null) {
                fd.append(k, '');
              } else {
                fd.append(k, v);
              }
            }
          }
        });
      }
      return fd;
    })(new FormData())();

    return toFormData(companyData);
  }

  /*
 * Returns "true" if the environment can be used for editing in Marketplace
 * */
  isActionDisabledByEnvironment(): boolean {
    return !environment.marketplace_master;
  }

  getPhoneRegex(): RegExp {
    return /^[0-9+]+$/;
  }

  getWebsiteRegex(): string {
    return '(https?://)?([\\da-zA-Z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  }

  getDomainRegex(): string {
    return '^[a-zA-Z0-9]+[a-zA-Z0-9-_]*[a-zA-Z0-9]$|^[a-zA-Z0-9]$';
  }

  /*
 * Reloads current route without refresh the page
 * */
  softReloadCurrentRoute(): Promise<boolean> {
    const currentUrl = this.router.url;
    return this.router.navigate([currentUrl]);
  }

  getTableFeType(feType: CanvasFunctionalElementType): TableFeTypes {
    if (feType === CanvasFunctionalElementType.Table) {
      return TableFeTypes.Requirement;
    } else if (feType === CanvasFunctionalElementType.Test_Script) {
      return TableFeTypes.TestScript;
    } else if (feType === CanvasFunctionalElementType.TraceMatrixTable) {
      return TableFeTypes.TraceMatrixTable;
    }
  }

  /*
  * Returns TM links for certain rows
  * */
  getTraceMatrixLinks(stepUuid: string, renderedRows: ICanvasStepDataRow[], cb?: (renderedRows) => void): Subscription {
    return this.traceMatrixLinksService.getLinksForRows(stepUuid, {
      row_uuids: renderedRows.map(row => row.uuid)
    }).subscribe(data => {
      this.mapTraceMatrixLinks(data.mappings, renderedRows);
      if (cb) {
        cb(renderedRows);
      }
    });
  }

  /*
  * Map each TM link to a row
  * */
  private mapTraceMatrixLinks(data: {
    [key: string]: LinksList[]
  }, renderedRows: ICanvasStepDataRow[]) {
    renderedRows.forEach(row => {
      if (data[row.uuid]) {
        row.links = data[row.uuid];
      }
    });
  }

  /*
  * Returns true if the row is passed, is fail or is NA
  * */
  hasTestResultCheckmark(row: ICanvasStepDataRow): boolean {
    return [1, true].includes(row.test_result_link?.is_na) || [0, 1, false, true].includes(row.test_result_link?.is_passed);
  }

  /*
  * Returns the row that has deviation with not proceed, if any
  * */
  getRowWithFailAndNotProceed(rows: ICanvasStepDataRow[]): ICanvasStepDataRow {
    return rows.find(row => row.test_result_link?.fail_type === IFailType.FailWithDeviationAndNotProceed);
  }

  /*
 * Returns true if the user can see only My Packages section
 * */
  canViewOnlyMyPackages(user: IUser): boolean {
    return !user.permissions.includes(Permissions.viewMarketplace) && user.permissions.includes(Permissions.viewMyPackages);
  }

  /*
 * Generates Nav Menu items
 * */
  generateNavMenu(
    permissions: PermissionList,
    isBtrAdmin: boolean,
    company: Company,
    currentRoute?: string
  ): INavMenuItem[] {
    return [{
      url: this.routingService.HOME.url(),
      text: 'Homepage',
      iconClass: 'icon-n-home',
      accessLevels: [AccessLevel.BTRAdmin, AccessLevel.User],
      routerLinkActive: 'is-active',
      isVisible: permissions.viewHomepage || isBtrAdmin,
      priority: 1
    }, {
      url: this.routingService.TEMPLATES.url(),
      text: 'Templates',
      iconClass: 'icon-n-editor',
      accessLevels: [AccessLevel.User],
      routerLinkActive: 'is-active',
      className: currentRoute?.includes('/request-access') && currentRoute?.includes('accessType=Templates') ? 'is-active' : null,
      isVisible: permissions.viewTemplateListing,
      priority: 2
    }, {
      url: this.routingService.DOCUMENTS.url(),
      text: 'Workspace',
      iconClass: 'icon-n-workspace',
      accessLevels: [AccessLevel.User],
      routerLinkActive: (currentRoute?.includes('/workflows') || currentRoute?.includes('/documents')) && !currentRoute?.includes('/audit-trail/') && !currentRoute?.includes('/reports/')? 'is-active' : null,
      className: currentRoute?.includes('/request-access') && currentRoute?.includes('accessType=Documents') ? 'is-active' : null,
      isVisible: permissions.viewWorkspaceRuntimeListing,
      priority: 3
    }, 
    {
      url: this.routingService.REPORTS.url(),
      text: 'Reports',
      iconClass: 'icon-n-reports',
      accessLevels: [AccessLevel.User],
      routerLinkActive: 'is-active',
      isVisible: permissions.viewReportsPage,
      priority: 4
    }, 
    {
      url: this.routingService.MY_TASKS.url(),
      text: 'My Tasks',
      iconClass: 'icon-n-tasks',
      accessLevels: [AccessLevel.User],
      routerLinkActive: 'is-active',
      isVisible: permissions.viewTasksPage,
      priority: 5
    }, {
      url: this.routingService.COMPANY_ADMIN.url(),
      text: 'Company Admin',
      iconClass: 'icon-n-admin',
      accessLevels: [AccessLevel.User],
      routerLinkActive: 'is-active',
      isVisible: permissions.companyAdminPanelAccess,
      priority: 6
    }, {
      url: this.routingService.AUDIT_TRAIL.url(),
      text: 'Audit Trail',
      iconClass: 'icon-n-audit-trail',
      accessLevels: [AccessLevel.User],
      routerLinkActive: 'is-active',
      isVisible: permissions.viewAuditTrail,
      priority: 9
    }, {
      url: this.routingService.MARKETPLACE_MY_PACKAGES_DOWNLOADS.url(),
      queryParams: { hasMyPackagesReferrer: true },
      text: 'My Packages',
      iconClass: 'icon-n-my-packages',
      accessLevels: [AccessLevel.User],
      routerLinkActive: currentRoute?.includes('hasMyPackagesReferrer=true') ? 'is-active' : null,
      isVisible: permissions.viewMyPackages,
      priority: 8
    }, {
      url: this.routingService.ADMIN.url(),
      text: 'Admin',
      iconClass: 'icon-n-admin',
      accessLevels: [AccessLevel.BTRAdmin],
      routerLinkActive: 'is-active',
      isVisible: true,
      priority: 10
    }, {
      url: this.routingService.COMPANIES.url(),
      text: 'Companies',
      iconClass: 'icon-icc_pdf',
      accessLevels: [AccessLevel.BTRAdmin],
      routerLinkActive: 'is-active',
      isVisible: true,
      priority: 11
    }, {
      url: this.routingService.PLATFORM_MONITORING_POD.url(),
      text: 'Monitoring',
      iconClass: 'icon-icn_cardView',
      accessLevels: [AccessLevel.BTRAdmin],
      routerLinkActive: 'is-active',
      isVisible: true,
      priority: 14
    }, {
      url: this.routingService.CMS.url(),
      text: 'CMS',
      iconClass: 'icon-icc_projects',
      accessLevels: [AccessLevel.BTRAdmin],
      routerLinkActive: 'is-active',
      isVisible: true,
      priority: 13
    }, {
      url: this.routingService.MARKETPLACE.url(),
      text: 'Marketplace',
      iconClass: 'icon-n-marketplace',
      accessLevels: [AccessLevel.BTRAdmin, AccessLevel.User],
      routerLinkActive: !currentRoute?.includes('hasMyPackagesReferrer=true') && currentRoute?.includes('marketplace/') ? 'is-active' : null,
      isVisible: permissions.viewMarketplace || isBtrAdmin,
      priority: 7
    }, {
      url: this.routingService.GLOBAL_SYSTEMS.url(),
      text: 'Systems',
      iconClass: 'icon-n-systems',
      accessLevels: [AccessLevel.BTRAdmin],
      routerLinkActive: 'is-active',
      isVisible: true,
      priority: 12
    }, {
      url: this.routingService.AUDIT_TRAIL_ADMIN.url(),
      text: 'Audit Trail',
      iconClass: 'icon-n-audit-trail',
      accessLevels: [AccessLevel.BTRAdmin],
      routerLinkActive: 'is-active',
      isVisible: true,
      priority: 15
    },
    ];
  }

  getWorkflowDataWithSessions(impactAssessmentService: ImpactAssessmentService, workflow: IWorkflowRuntime, cb) {
    const _workflow: IWorkflowRuntime = cloneDeep(workflow);
    _workflow.steps = _workflow.steps.filter(step => _workflow.active_branch_step_uuids?.includes(step.uuid));
    const impactAssessmentStep = _workflow.steps.find(step => step.fe_type === CanvasFunctionalElementType.Impact);
    if (!impactAssessmentStep) {
      cb(_workflow);
      return;
    }
    const cds = impactAssessmentStep.step_data.impact_assessment_results.change_descriptions;
    impactAssessmentService.getIASessionsWithProgress(impactAssessmentStep.uuid, _workflow.version_root_uuid, _workflow.uuid)
      .pipe(take(1))
      .subscribe(res => {
        cds.forEach((cd, i) => cd.session = res[i].impact_assessment);
        cb(_workflow);
      });
  }

  cleanGeneralFEsNullTags(workflow: IWorkflowRuntime) {
    workflow.steps.forEach(step => {
      if (step.fe_type === CanvasFunctionalElementType.General_Table && step.step_data.content) {
        step.step_data.content = step.step_data.content.replace(new RegExp(`style="null"`, 'g'), '');
      }
    });
  }

  orderUsersList(users: IUserData[], ownerUuid: string, withOwner: boolean): IUserData[] {
    //  Sort alphabetically both Users and Groups
    users.sort((a, b) => {
      const aData = a.data as any;  //  We cannot use the proper type here because we compare below different types
      const bData = b.data as any;
      const aItem = aData.first_name?.toLowerCase() ? aData.first_name?.toLowerCase() : aData.name?.toLowerCase();
      const bItem = bData.first_name?.toLowerCase() ? bData.first_name?.toLowerCase() : bData.name?.toLowerCase();
      return (aItem > bItem) ? 1 : ((bItem > aItem) ? -1 : 0);
    });
    //  Move the owner to the top
    if (withOwner) {
      const currentUsr = users.find(u => (u.data as IUserLite).uuid === ownerUuid);
      if (currentUsr) {
        users = users.filter(u => (u.data as IUserLite).uuid !== ownerUuid);
        users.unshift(currentUsr);
      }
    }
    return users;
  }

  populateUsersList(data: WorkflowAccessListResponse, list: IUserData[], withOwner: boolean): any[] {
    let ownerUuid: string;
    list = [];
    if (data.owner) {
      ownerUuid = data.owner.uuid;
      list.push({ type: 'user', data: data.owner });
    }
    if (data.users.length) {
      //  Remove the owner from the users list
      data.users = data.users.filter(user => user.uuid !== data.owner?.uuid);
      const mappedUsers = data.users.map(u => ({ type: 'user', data: u }));
      list.push(...mappedUsers);
    }
    if (data.groups.length) {
      const mappedGroups = data.groups.map(u => ({ type: 'group', data: u }));
      list.push(...mappedGroups);
    }
    list = this.orderUsersList(list, ownerUuid, withOwner);
    return [
      ownerUuid,
      ...list,
    ];
  }

  copyLink(mainRoute: string, versionRootUuid: string, Uuid: string): void {
    const hostname = window.location.href.replace(this.router.url, '');
    const path = `${hostname}/${mainRoute}/${versionRootUuid}/version/${Uuid}`;
    this.clipboard.copy(path);
    this.toastr.success('Link copied');
  }


  renderTableCellContent(element, company) {
    if (element == null) {
      return '';
    } else if (typeof element === 'string' && this.isValidDate(element)) {
      return this.dateFormat.transform(element, company.dateformat, company.timezone, company.timeformat);
    } else if (typeof element === 'object' && !Array.isArray(element)) {
      const keys = Object.keys(element);
      let result = '';
      if (keys.length) {
        result += '<ul>';
        keys.forEach(key => {
          result += `<li>${key}: ${this.renderTableCellContent(element[key], company)}</li>`;
        });
      }
      result += '</ul>';
      return result;
    } else if (Array.isArray(element)) {
      return typeof element[0] === 'object' ?
        element.map(el => {
          return `<ul>${Object.keys(el).map(key => `<li>${key}: ${this.renderTableCellContent(el[key], company)}</li>`).join('')}</ul>`;
        }).join('<br>') :
        element.join(', ');
    } else {
      return element;
    }
  }

  private isValidDate(str: string): boolean {
    // [NRESQ-11588] We must skip the 4 characters (digits) strings because moment sees them as date
    return str.length !== 4 && moment(str, '', true).isValid();
  }

  userHasTemporaryAccess(accessList: WorkflowAccessListResponse, currentUserUuid: string): boolean {
    return accessList?.users
      .find(user => user.uuid === currentUserUuid)?.type === TemplateAccessRequestType.TEMPORARY &&
      accessList.owner.uuid !== currentUserUuid;
  }

  userIsInAccessList(accessList: WorkflowAccessListResponse, currentUserUuid: string, userGroups: UserGroupsResource[]): boolean {
    return accessList.groups.filter(o => userGroups.some(({ uuid }) => o.group_uuid === uuid)).length > 0 ||
      !!accessList.users.find(user => user.uuid === currentUserUuid) ||
      accessList?.owner.uuid === currentUserUuid;
  }

  userIsInAccessList$(currentUserUuid: string, type: IWorkflowType, workflowUuid: string): Observable<boolean> {
    return forkJoin([
      this.aclService.getAccessList(type, workflowUuid),
      this.aclService.getCurrentUserGroup()
    ]).pipe(
      map(([accessList, userGroups]) => this.userIsInAccessList(accessList, currentUserUuid, userGroups))
    );
  }


  generateStepUuid(isTemplate: boolean) {
    if (isTemplate) {
      return this.store.dispatch(new CanvasActions.GenerateStepUuid()).pipe(
        take(1)
      );
    } else {
      return this.store.dispatch(new DocumentActions.GenerateStepUuid()).pipe(
        take(1)
      )
    }
  }

  saveWorkflow(isTemplate: boolean) {
    return this.store.dispatch(isTemplate ? new CanvasActions.SaveTemplate() : new DocumentActions.SaveWorkflow()).pipe(
      take(1)
    )
  }

  tmLinksUpdatedInCurrentDocument() {
    return this.store.dispatch(new DocumentActions.SaveWorkflow());
  }

  orderDataByKey(data, key) {
    return data.sort(
      (a, b) => (a[key]?.toLowerCase() > b[key]?.toLowerCase())
        ? 1
        : ((b[key]?.toLowerCase() > a[key]?.toLowerCase())
          ? -1
          : 0)
    );
  }

  orderFilterValues(data: ActiveFilter[]): ActiveFilter[] {
    const filters = cloneDeep(data);
    return filters.map(f => {
      if (f.value.selectValues?.length) {
        f.value.selectValues.sort(
          (a: IFilterSelectValue, b: IFilterSelectValue) => (a.label?.toLowerCase() > b.label?.toLowerCase())
            ? 1
            : ((b.label?.toLowerCase() > a.label?.toLowerCase())
              ? -1
              : 0)
        );
      }
      return f;
    });
  }

  /**
   * Based on Labels Changes Request and NRESQ-13481
   */
  getDocumentsTypeMapped(type: TemplateType) {
    return (type === TemplateType.SDOX) ? 'Document' : type;
  }
}
