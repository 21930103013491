export class StartTasksCheck {
  static readonly type = '[Tasks] StartTasksCheck';
  constructor(public offset: number, public limit: number) { }
}

export class StopTasksCheck {
  static readonly type = '[Tasks] StopTasksCheck';
}

export class GetTasks {
  static readonly type = '[Tasks] GetTasks';
  constructor(public offset: number, public limit: number) { }
}

export class MarkTaskAsSeen {
  static readonly type = '[Tasks] MarkTaskAsSeen';
  constructor(public uuid: string) { }
}
