export interface IReportListingConfiguration {
  key: string;
  title: string;
  columns: string[];
  columnsMapping: IReportListingColumnsMapping[];
  sortDefault: string;
  paginationKey: string;
  filterKey: string;
  savedViewsKey: string;
  searchFilterKey: string;
}

export interface IReportListingColumnsMapping {
  key: string;
  title: string;
  rowDataKey: string;
  isLink?: boolean;
  tooltip?: string;
  isNotSortable?: boolean;
}

export enum ReportsURLSectionTitle {
  systems = 'Systems',
  workflows = 'Workflows',
  'workflow-deliverables' = 'Workflow Deliverables',
  'test-scripts' = 'Test Scripts Executions',
  tasks = 'Tasks',
  reviews = 'Reviews',
  'periodic-reviews' = 'Periodic Reviews',
  'project-management' = 'Project Management',
}

export enum ReportsTitleURLKey {
  Reports = 'reports',
  Dashboard = 'dashboard',
  Systems = 'systems',
  Workflows = 'workflows',
  WorkflowDeliverables = 'workflow-deliverables',
  TestScripts = 'test-scripts',
  Tasks = 'tasks',
  Reviews = 'reviews',
  PeriodicReviews = 'periodic-reviews',
  ProjectManagement = 'project-management',
}

export enum ReportType {
  systems = 'systems_report',
  workflows = 'workflows_report',
  'workflow-deliverables' = 'deliverables_report',
  'test-scripts' = 'ts_execution_report',
  'tasks' = 'tasks_report',
  'project-management' = 'project_management_report',
}

export enum ReportTitle {
  Systems = 'Systems Report',
  Workflows = 'Workflows Report',
  WorkflowDeliverables = 'Workflow Deliverables Report',
  TestScripts = 'Test Scripts Executions Report',
  Tasks = 'Tasks Report',
  ProjectManagement = 'Project Management Report'
}

export enum ReportsListingActionType {
  CSV = 'csv',
  XLS = 'xls',
  PDF = 'pdf',
}

export interface ReportPrintingData {
  report_title: string;
  report_data_last_updated:  string;
  permission: boolean
}