import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IFilterDataValues } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private reportsPath = '/api/reports';
  private reportsDataSetPath = '/api/report-data-set';

  constructor(private apiService: ApiService) { }

  public getReportByReportType(category: string) {
    return this.apiService.get(`${this.reportsPath}/${category}`);
  }

  public updateReportByReportType(category: string) {
    return this.apiService.post(`${this.reportsPath}/${category}`, {});
  }

  public getReportDataSet(offset: number, limit: number, filters: IFilterDataValues) {
    return this.apiService.post(`${this.reportsDataSetPath}/list/${offset}/${limit}`, filters);
  }

  public printReportAsync(exportFormat: string, reportData: any) {
    return this.apiService.postToBackendPdfService(`${this.reportsDataSetPath}/export/${exportFormat}`, reportData);
  }
}
