import { ICompanyContact } from "@core/models";
import { ISortQueryParameters } from "./api-response.interface";

export interface ICompanySystem {
  name: string;
  company_uuid: string;
  vendor_name: string;
  uuid?: string;
  image_url?: string;
  image?: string;
  color_value_h?: number;
  global_system_uuid?: string;
  system_owner_contact_uuid?: string;
  business_owner_contact_uuid?: string;
  system_owner_contact?: ICompanyContact;
  business_owner_contact?: ICompanyContact;
  status?: SystemStatusType;
  periodic_review_date?: string;
  implementation_date?: string;
  alert_days_ahead?: number;
  created_at?: string;
  updated_at?: string;
}

export interface ICompanySystemSettings {
  uuid?: string;
  periodic_review_date?: string;
  implementation_date?: string;
  alert_days_ahead?: number;
}

export interface ISortQuerySystemParameters extends ISortQueryParameters {
  for_private: number;
}

export interface ICompanySystemLocal extends ICompanySystem {
  selected: boolean;
}

export interface IAlerts {
  value: string;
  label: string;
}

export enum SystemActionType {
  CREATE_LOCAL_SYSTEM = 'CREATE_LOCAL_SYSTEM',
  CHOOSE_GLOBL_SYSTEM = 'CHOOSE_GLOBL_SYSTEM'
}

export enum SystemActionModalType {
  IMPORT = 'Import',
  REPLACE = 'Replace'
}

export enum SystemStatusType {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  PENDING = 'pending'
}
