import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AUTOLOGOUT_SESSION_ID, COGNITO_LOCAL_STORAGE_KEY_PREFIX, COGNITO_NEW_PASSWORD_REQUIRED, COGNITO_VERIFY_OTP, MENU_SIZES } from '@core/constants/app-constants';
import { IUser } from '@core/models';
import { ExtendSessionService, UserService } from '@core/services';
import { AuthenticationService } from '@core/services/authentication.service';
import { RoutingService } from '@core/services/routing.service';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Select, Store } from '@ngxs/store';
import { GetGeneratedFiles } from '@store/async-pdf/async-pdf.actions';
import { AsyncFileDynamoDBResponse } from '@store/async-pdf/async-pdf.model';
import { AsyncPdfSelectors } from '@store/async-pdf/async-pdf.selectors';
import { CompanySelectors } from '@store/company/company.selectors';
import { Auth, Hub } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, fromEvent, merge } from 'rxjs';
import { filter, switchMap, take, throttleTime } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppSelectors } from './store/app.selectors';
import { GetCurrentUser, Login, Logout } from './store/user/user.actions';
import { UserSelectors } from './store/user/user.selectors';
import { IEnvironmentName } from 'src/environments/environment.interface';
import { GetPermissionMatrixLite } from '@store/company/company.actions';
import { HubSpotService } from '@core/services/hub-spot.service';
import { GetNotifications } from '@store/notification/notification.actions';
import { GetConfig } from '@store/config/config.actions';
import { ConfigSelectors } from '@store/config/config.selectors';
import { registerLicense } from '@syncfusion/ej2-base';
// tslint:disable-next-line:ban-types
declare const gtag: Function;

const DISABLED_USER_ERROR = 'User is disabled.';
const INCORRECT_CREDENTIALS_ERROR = 'Incorrect username or password.';
const WRONG_STATE_ERROR = 'User password cannot be reset in the current state.';
const RESET_TEMPORARY_PASSWORD = 'A new user account was created for you. Please check your email for instructions.';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class AppComponent implements OnInit {
  readonly title = 'Res_Q';
  loggedIn: boolean;
  isMenuOpened = false;
  environment = environment;
  isMenuDisplayed = true;
  isSignaturePasswordBannerVisible: boolean;
  files: AsyncFileDynamoDBResponse[] = [];

  @Select(AppSelectors.isMenuOpened)
  public isMenuOpened$: Observable<boolean>;

  @Select(AppSelectors.getContentFlexSize)
  public contentFlexSize$: Observable<string>;

  @Select(AppSelectors.isFullScreen)
  public isFullScreen$: Observable<string>;

  @Select(UserSelectors.getCurrentUser)
  public user$: Observable<IUser>;

  @Select(UserSelectors.hasSignaturePassword)
  public hasSignaturePassword$: Observable<boolean>;

  @Select(UserSelectors.isBTRAdmin)
  isBtrAdmin$: Observable<boolean>;

  public isOnline: boolean;

  private menuOpen$: Subscription;
  private loggedIn$: Subscription;
  private currentUser$: Subscription;
  private routerEvents$: Subscription;
  private asyncFiles$: Subscription;
  private inactivityDetectorForExtendSession$: Subscription;
  authLoginStarted = false; // Flag to prevent multiple login calls

  constructor(
    private cdr: ChangeDetectorRef,
    public router: Router,
    private store: Store,
    private route: ActivatedRoute,
    public routingService: RoutingService,
    public authenticator: AuthenticatorService,
    public authService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private extendSessionService: ExtendSessionService,
    private hubSpotService: HubSpotService
  ) {
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          if (localStorage.getItem(COGNITO_NEW_PASSWORD_REQUIRED)) {
            this.userService.updateLoginPasswordChanged().subscribe(() => {
              localStorage.removeItem(COGNITO_NEW_PASSWORD_REQUIRED);
              this.toastr.success('Your password was created successfully');
            });
          }
          if (localStorage.getItem(COGNITO_VERIFY_OTP)) {
            localStorage.removeItem(COGNITO_VERIFY_OTP);
            break;
          }
          if (!this.authLoginStarted) {
            this.authLoginStarted = true;
            this.authenticationService.updateSuccessAuth().pipe(take(1)).subscribe(() => {
              this.handleLoggedInActions();
              this.store.dispatch(new Login());
              this.router.navigate([this.routingService.HOME.url()]);
            }, () => {
              this.authLoginStarted = false;
            });
          }
          break;
        case 'signOut':
          this.authLoginStarted = false;
          this.authService.signOutTriggered = false;
          if (this.store.selectSnapshot(UserSelectors.isLoggedIn)) {
            this.handleLoggedOutActions();
            this.store.dispatch(new Logout(false));
          }
          break;
        case 'signIn_failure':
          this.authLoginStarted = false;
          if (data.payload.data.message === DISABLED_USER_ERROR) {
            data.payload.data.message = INCORRECT_CREDENTIALS_ERROR;
          }
          break;
        case 'forgotPassword_failure':
          this.authLoginStarted = false;
          if (data.payload.data.message === WRONG_STATE_ERROR) {
            data.payload.data.message = RESET_TEMPORARY_PASSWORD;
          }
          break;
      }
      this.cdr.markForCheck();
    });
  }

  async ngOnInit() {
    this.isOnline = navigator.onLine;
    this.authService.configureAmplify();

    this.menuOpen$ = this.store.select(AppSelectors.isMenuOpened).subscribe(isMenuOpened => {
      this.isMenuOpened = isMenuOpened;
      this.cdr.markForCheck();
    });

    this.loggedIn$ = this.store.select(UserSelectors.isLoggedIn).subscribe(async isLoggedIn => {
      this.loggedIn = isLoggedIn;
      this.cdr.markForCheck();
    });

    try {
      await Auth.currentAuthenticatedUser()
      if (!this.authLoginStarted) {
        this.authLoginStarted = true;
        if (localStorage.getItem(AUTOLOGOUT_SESSION_ID)) {
          this.handleLoggedInActions();
        } else {
          this.authenticationService.updateSuccessAuth().pipe(take(1)).subscribe(() => {
            this.handleLoggedInActions();
          })
        }
      }
    } catch (err) {
      this.handleLoggedOutActions();
    }

    this.getUserAndUpdateHubSpot();

    this.routerEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.getUserAndUpdateHubSpot();
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
      this.isMenuDisplayed = Boolean(this.route.snapshot.queryParams.secondaryWindow) !== true
        && this.route.snapshot.queryParams.isPackagePreviewMode !== 'true';
      this.isSignaturePasswordBannerVisible = event.url.startsWith('/home');
    });

    this.store.select(CompanySelectors.getCurrentCompany)
      .pipe(
        filter(company => !!company?.uuid),
      ).subscribe(company => {
        const currentCompanyConfig = company.config;
        const autoLogoutCompanySettings = (((currentCompanyConfig.autoLogout.hours * 60) + (currentCompanyConfig.autoLogout.minutes)) / 2) * 60 * 1000;
        if (autoLogoutCompanySettings) {
          this.inactivityDetectorForExtendSession$ = merge(
            fromEvent(window, 'scroll'),
            fromEvent(window, 'wheel'),
            fromEvent(window, 'keypress'),
            fromEvent(window, 'mouseup'),
          ).pipe(
            throttleTime(autoLogoutCompanySettings),
            switchMap(() => this.extendSessionService.extendSession())
          ).subscribe();
        }
      });

    if (environment.environmentName === IEnvironmentName.qa) {
      window.addEventListener('storage', (event) => {
        // When local storage changes, dump the list to the console.
        const localStorageItems = this.getLocalStorageItems(COGNITO_LOCAL_STORAGE_KEY_PREFIX);
      });
    }
  }

  private getUserAndUpdateHubSpot(): void {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
    this.currentUser$ = this.store.select(UserSelectors.getCurrentUser).pipe(
      filter(user => !!user),
    ).subscribe(user => {
      this.store.dispatch(new GetGeneratedFiles(user.uuid));
      this.store.dispatch(new GetNotifications());
      this.hubSpotService.loadScript();
    });
  }

  private getLocalStorageItems(query) {
    let i;
    const results = [];
    for (i in localStorage) {
      if (localStorage.hasOwnProperty(i)) {
        if (i.match(query) || (!query && typeof i === 'string')) {
          const value = localStorage.getItem(i);
          results.push({ key: i, val: value });
        }
      }
    }
    return results;
  }

  private handleLoggedInActions() {
    if (this.asyncFiles$) {
      this.asyncFiles$.unsubscribe();
    }
    this.asyncFiles$ = this.store.select(AsyncPdfSelectors.getGeneratedFiles).subscribe(res => {
      this.files = res;
      this.cdr.markForCheck();
    });

    const currentUser = this.store.selectSnapshot(UserSelectors.getCurrentUser);
    if (!currentUser) {
      this.store.dispatch(new GetPermissionMatrixLite());
      this.store.dispatch(new GetCurrentUser());
    }
    this.store.dispatch(new GetConfig());
    this.store.select(ConfigSelectors.syncfusionKey)
      .pipe(filter(key => !!key))
      .subscribe(key => {
        registerLicense(key);
      });
  }

  private handleLoggedOutActions() {
    this.authLoginStarted = false;
    this.hubSpotService.removeScript();
    if (this.asyncFiles$) {
      this.asyncFiles$.unsubscribe();
    }
  }

  public getContentClass(size: string): string {
    switch (size) {
      case `calc(100% - ${MENU_SIZES.expanded})`:
        return 'app-content-expanded';
      case `calc(100% - ${MENU_SIZES.collapsed})`:
        return 'app-content-collapsed';
      case `calc(100% - 210px)`:
        return 'app-content-opened';
      case '88':
      case '100':
        return `flex-${size}`;
      default:
        return '';
    }
  }

  @HostListener('window:offline', ['$event'])
  offline() {
    this.isOnline = false;
  }

  @HostListener('window:online', ['$event'])
  onlineEvent() {
    this.isOnline = true;
  }
}
