import {
  CommonFiltersItemsModel,
  CommonFiltersModel, FilterModel, FilterModelArray,
  IFilterSelectValue,
  IUserGroup,
  PrefixType
} from '@core/interfaces';
import { IUserLite } from '@core/models';
import { DatepickerRange } from '@shared/components/datepicker/datepicker.models';

export const prefixSelectValues: IFilterSelectValue[] = [
  {
    value: PrefixType.Document,
    label: 'Document',
  },
  {
    value: PrefixType.Workflow,
    label: 'Workflow',
  },
];

const docTypeSelectValues: IFilterSelectValue[] = [
  {
    value: 'SDOX',
    label: 'Document'
  },
  {
    value: 'ECM',
    label: 'ECM',
  },
  {
    value: 'ILM',
    label: 'ILM'
  }
];

const sdoxCategorySelectValues: IFilterSelectValue[] = [
  {
    value: 'test_script_execution',
    label: 'Executed Test Script Documents'
  },
  {
    value: 'general_document',
    label: 'General Documents'
  },
  {
    value: 'requirement_document',
    label: 'Requirements Documents',
  },
  {
    value: 'test_script',
    label: 'Test Scripts Documents'
  },
  {
    value: 'trace_matrix',
    label: 'Trace Matrix Documents'
  }
];


export const statusSelectValues: IFilterSelectValue[] = [
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'cancelled',
    label: 'Canceled'
  },
  {
    value: 'draft',
    label: 'Draft'
  },
  {
    value: 'in_execution',
    label: 'In Execution'
  },
  {
    value: 'in_review',
    label: 'In Review'
  },
  {
    value: 'waiting_for_signature',
    label: 'Waiting for Signature'
  },
];

export const workspaceFiltersDefaults: CommonFiltersItemsModel<WorkspaceFilters> = {
  search: {
    label: 'Search...',
    selected: ''
  },
  filters: {
    type: {
      label: 'Type',
      selected: 'All',
      selectValues: docTypeSelectValues,
      isMultiselect: true
    },
    sdox_document_category: {
      label: 'Document Category',
      selected: 'All',
      selectValues: sdoxCategorySelectValues,
      isMultiselect: true
    },
    system_uuid: {
      label: 'System',
      selected: 'All',
      selectValues: [],
      isMultiselect: true
    },
    status: {
      label: 'Status',
      selected: null,
      selectValues: statusSelectValues,
      isMultiselect: true
    },
    owner_user_uuid: {
      label: 'Owner',
      selectValues: [],
      selected: null,
      displayFn: (item: IUserLite) => `${item.first_name} ${item.last_name} ${item.is_active ? '' : '(deactivated)'}`,
      displayInfoFn: (item: IUserLite) => `${item.email}`,
      displaySecondaryInfoFn: (item: IUserLite) => `${item.role.name}`,
      displayThirdInfoFn: (item: IUserLite) => `${item.department.name}`,
      isMultiselect: true
    },
    access_groups_uuids: {
      label: 'Access Group',
      selected: null,
      selectValues: [],
      displayFn: (item: IUserGroup) => `${item.name}`,
      isMultiselect: true
    },
    filter_tag_value_uuid: {
      label: 'Filter Tags',
      selected: null,
      selectValues: [],
      children: null,
      data: {},
      isMultiselect: true
    },
    prefix: {
      label: 'Prefix',
      selected: null,
      selectValues: prefixSelectValues,
      children: null,
      data: {},
      isMultiselect: false // only one children value can be selected
    },
    created_at: {
      label: 'Creation Date',
      selected: null,
      shouldGetSelectedValueFromArray: true
    },
    updated_at: {
      label: 'Last Updated',
      selected: null,
      shouldGetSelectedValueFromArray: true
    },
    access_type: {
      label: '',
      selected: null,
      hiddenFromView: true
    },
    all_versions: {
      label: '',
      selected: null,
      hiddenFromView: true,
      shouldGetSelectedValueFromArray: true
    }
  }
};

export interface WorkspaceFilters {
  type: FilterModelArray<string, IFilterSelectValue>;
  sdox_document_category: FilterModelArray<string, IFilterSelectValue>;
  system_uuid: FilterModelArray<string, IFilterSelectValue>;
  status: FilterModelArray<string, IFilterSelectValue>;
  owner_user_uuid: FilterModelArray<string, IFilterSelectValue>;
  access_groups_uuids: FilterModelArray<string, IFilterSelectValue>;

  filter_tag_value_uuid: FilterModelArray<string, IFilterSelectValue>;
  prefix: FilterModelArray<string, IFilterSelectValue>;
  created_at?: FilterModel<DatepickerRange>;
  updated_at?: FilterModel<DatepickerRange>;

  access_type?: FilterModelArray<string, IFilterSelectValue>;
  all_versions?: FilterModelArray<boolean, IFilterSelectValue>;
}

export interface WorkspaceFiltersModel extends CommonFiltersModel<WorkspaceFilters> {
  loading: boolean;
}
