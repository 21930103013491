import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { AUTOLOGOUT_SESSION_ID, COGNITO_LOCAL_STORAGE_KEY_PREFIX, FILTERS_LOCAL_STORAGE_KEY, PAGINATION_LOCAL_STORAGE_KEY } from '@core/constants/app-constants';
import {
  ICompanyPoolConfig,
  IResetSignaturePasswordWithToken,
  IStoreSignaturePassword,
  IUpdateSignaturePassword
} from '@core/interfaces';
import { Store } from '@ngxs/store';
import { StopAsyncPdfCheck } from '@store/async-pdf/async-pdf.actions';
import { StopTasksCheck } from '@store/tasks/tasks.actions';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Observable, from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserState } from 'src/app/store/user/user.state';
import { SSOIdentityProviderType } from '../../admin/shared/modules/company-details/sso-add-idp-modal/idp.interface';
import { StopLinksStatusCheck } from '../../documents/store/document.actions';
import { StopDownloadStatusCheck, StopImportDataLockStatusCheck } from '../../marketplace/store/marketplace.actions';
import { MonitoringService } from '../../monitoring.service';
import { ApiService } from './api.service';
import { RoutingService } from './routing.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  signOutTriggered = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private routingService: RoutingService,
    public monitoringService: MonitoringService,
    private store: Store,
    private zone: NgZone
  ) { }

  private async getSignEndpointSession(): Promise<string> {
    try {
      const session = await Auth.currentSession();
      const identities = session.getIdToken()?.payload?.identities;
      if (identities && identities[0].providerType === SSOIdentityProviderType.OIDC) {
        const poolConfig: ICompanyPoolConfig = JSON.parse(localStorage.getItem('pool_config'));
        return poolConfig.idp_data.filter(idp => idp.name === identities[0].providerName)[0].end_session_endpoint;
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  logout(forceSignOut = true): Observable<any> {
    if (forceSignOut) {
      if (this.signOutTriggered) {
        return of(false);
      }

      this.signOutTriggered = true;
      return from(this.getSignEndpointSession())
        .pipe(
          catchError(err => {
            return of(err);
          }),
          switchMap((url) => {
            localStorage.setItem('amplify-signin-with-hostedUI', 'true');

            // Only OIDC sign in, will have url ("end_session_endpoint")
            if (url && typeof url === 'string') {
              window.open(url as string, '_blank');
            }

            // Auth.signOut will reload the page, it will not continue the stream
            return from(Auth.signOut({ global: true })).pipe(
              catchError(() => {
                this.signOutTriggered = false;
                return this.cleanAuthData();
              }),
            );
          }));
    } else {
      return this.cleanAuthData();
    }
  }

  private cleanAuthData(): Observable<any> {
    localStorage.removeItem('loggedIn');
    this.clearCognitoLocalStorageData();
    localStorage.removeItem(AUTOLOGOUT_SESSION_ID);
    this.monitoringService.removeMonitorUser();
    this.store.dispatch(new StopTasksCheck());
    this.store.dispatch(new StopAsyncPdfCheck());
    this.store.dispatch(new StopDownloadStatusCheck());
    this.store.dispatch(new StopImportDataLockStatusCheck());
    this.store.dispatch(new StopLinksStatusCheck());

    this.zone.run(() => {
      this.router.navigate([this.routingService.LOGIN.url()]);
    });
    return this.store.dispatch(
      new StateResetAll(UserState)
    );
  }

  /*
  * Used to invalidate the refresh token
  * */
  logoutUser(): Observable<{ success: true }> {
    return this.apiService.post('/api/user/logout', {});
  }

  resetPassword(email: string) {
    return this.apiService.post('/api/password/email', { email });
  }

  createSignaturePassword(payload: IStoreSignaturePassword): Observable<{ success: true }> {
    return this.apiService.post('/api/user-signature-password', payload);
  }

  updateSignaturePassword(payload: IUpdateSignaturePassword): Observable<{ success: true }> {
    return this.apiService.put('/api/user-signature-password', payload);
  }

  resetSignaturePassword(payload: IResetSignaturePasswordWithToken): Observable<{ success: true }> {
    return this.apiService.post('/api/password-change', payload);
  }

  forgotSignaturePassword(): Observable<{ success: true }> {
    return this.apiService.post('/api/user-signature-password/forgot', {});
  }

  resetForgotSignaturePassword(payload: IResetSignaturePasswordWithToken): Observable<{ success: true }> {
    return this.apiService.post('/api/user-signature-password/token', payload);
  }

  getBlacklistedFileTypeExtensions(): Observable<string[]> {
    return this.apiService.get('/api/file-types/blacklist');
  }

  updateSuccessAuth() {
    return this.apiService.post('/api/login/success-auth', {});
  }

  /**
   * Will force remove Cognito Keys from localStorage
   */
  private clearCognitoLocalStorageData() {
    Object.keys(localStorage)
      .filter(x => x.indexOf(COGNITO_LOCAL_STORAGE_KEY_PREFIX) > -1)
      .forEach(x => localStorage.removeItem(x));
  }

  clearLocalStorageFilters() {
    Object.values(FILTERS_LOCAL_STORAGE_KEY).forEach(filterLocalStorageKey => localStorage.removeItem(filterLocalStorageKey));
  }

  clearLocalStoragePagination() {
    Object.values(PAGINATION_LOCAL_STORAGE_KEY).forEach(paginationLocalStorageKey => {
      Object.keys(localStorage).find((localStorageKey: string) => {
        if (localStorageKey.startsWith(paginationLocalStorageKey)) {
          localStorage.removeItem(localStorageKey);
        }
      })
    })
  }

  configureAmplify() {
    const poolConfig: ICompanyPoolConfig = JSON.parse(localStorage.getItem('pool_config'));

    if (poolConfig) {
      Amplify.configure({
        Auth: {
          userPoolId: poolConfig.user_pool_id,
          userPoolWebClientId: poolConfig.user_pool_client_id,
          oauth: {
            domain: poolConfig.urls.domain,
            scope: ['email', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
            responseType: 'code',
            redirectSignIn: poolConfig.urls.login,
            redirectSignOut: poolConfig.urls.logout
          }
        }
      });
    }
  }

  async forceCognitoNewSession(): Promise<CognitoUserSession> {
    return new Promise(async (resolve, reject) => {
      const session = await Auth.currentSession().catch(e => {
        return null;
      });
      const user = await Auth.currentAuthenticatedUser().catch(e => {
        return null;
      });
      (user as CognitoUser).refreshSession(session.getRefreshToken(), (err, session) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(session);
      });
    })
  }
}
