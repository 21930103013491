import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CHUNK_SIZE_FOR_API_CALLS } from '@core/constants/app-constants';
import { Store } from '@ngxs/store';
import { UserSelectors } from '@store/user/user.selectors';
import moment from 'moment/moment';
import { interval, Observable, of, Subject } from 'rxjs';
import { catchError, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { IApiPaginatedDataBase, ISortQueryParameters, ITask, ITasksMetadata, TaskType } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private pollInterval = 9000;
  private tasks$: Subject<any>;
  private readonly tasksPath = `/api/user-task`;

  constructor(private apiService: ApiService, private store: Store) { }

  /*
  * Checks if a long polling subscription is already created, if not creates it
  * It should create a new subscription on refresh and login
   */
  startTasksCheck(offset: number, limit: number) {
    this.stopTasksCheck();
    this.tasks$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getTasks(offset, limit)),
      takeUntil(this.tasks$)
    );
  }

  stopTasksCheck() {
    if (this.tasks$) {
      this.tasks$.next(null);
      this.tasks$.complete();
    }
  }

  getTasksMetadata(): Observable<ITasksMetadata> {
    return this.apiService.get<IApiPaginatedDataBase<ITasksMetadata>>(`${this.tasksPath}/meta`);
  }

  getTasks(offset: number, limit: number, sortQueryParams?: ISortQueryParameters, filters?: { due_date_category: TaskType })
    : Observable<IApiPaginatedDataBase<ITask>> {
    // We will get chunks of data if the requested limit is greater than 'CHUNK_SIZE_FOR_API_CALLS'
    let isDataRetrieved = false;
    const allTasks = { data: [], total: 0 };
    const allTasks$ = new Subject<IApiPaginatedDataBase<ITask>>();

    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    const chunkSize = limit > CHUNK_SIZE_FOR_API_CALLS ? CHUNK_SIZE_FOR_API_CALLS : limit;
    const getData = async () => {
      while (!isDataRetrieved) {
        const res = await this.getTasksChunk(offset, chunkSize, queryParams, filters).toPromise();
        const tasks = res.data.filter(task => task).map(task => ({
          ...task,
          task_type: task.task_type,
          due_date_timestamp: moment(task.due_date).valueOf()
        }));

        allTasks.total = res.total;
        allTasks.data.push(...tasks);
        if (res.data.length < chunkSize || allTasks.data.length === limit) {
          allTasks$.next(allTasks);
          allTasks$.complete();
          isDataRetrieved = true;
        } else {
          offset += chunkSize;
        }
      }
    }
    getData();
    return allTasks$.asObservable();
  }

  public markTaskAsSeen(uuid: string) {
    return this.apiService.patch(`${this.tasksPath}/${uuid}/seen`, { is_seen: 1 });
  }

  private getTasksChunk(pageIndex: number, pageSize: number, queryParams: HttpParams,
    filters: { due_date_category: TaskType }) {
    return this.apiService.get<IApiPaginatedDataBase<ITask>>(`${this.tasksPath}/${pageIndex}/${pageSize}`,
      this.apiService.addFilterQueryParams(queryParams, filters)
    ).pipe(
      filter((res: IApiPaginatedDataBase<ITask>) => res && !!res.data),
      catchError(err => {
        if (!this.store.selectSnapshot(UserSelectors.isLoggedIn)) {
          this.stopTasksCheck();
        }
        // TODO - check what to do in case BD throws error
        return of({
          data: [],
          total: 0
        });
      })
    );
  }
}
