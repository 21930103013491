import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationResponse, NotificationTemplate, TemplateAccessRequestType } from '@core/interfaces';
import { Company } from '@core/models';
import { HelperService, NotificationsService, RoutingService, RuntimeService, StateService, TasksService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import { UserSelectors } from '@store/user/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CompanySelectors } from 'src/app/store/company/company.selectors';
import { MarkNotificationAsRead } from 'src/app/store/notification/notification.actions';

@Component({
  selector: 'app-notification-menu-item',
  template: ''
})
export class NotificationMenuItemComponent {
  notificationsWithoutErrorMessage = [NotificationTemplate.SignatureRequestRejectedToRemainingApprover];

  @Select(CompanySelectors.getCurrentCompany)
  company$: Observable<Company>;

  constructor(protected stateService: StateService,
    protected router: Router,
    protected routingService: RoutingService,
    protected notificationsService: NotificationsService,
    protected store: Store,
    protected toastr: ToastrService,
    private helperService: HelperService,
    private tasksService: TasksService,
    private runtimeService: RuntimeService
  ) { }

  goToMyTasks(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.MY_TASKS.url()]);
  }

  goToOriginalWorkflow(notification: NotificationResponse, goToStep?: boolean): void {
    this.markAsRead(notification);

    if (!notification.data.link?.parameters?.original_workflow_uuid) {
      if (!this.notificationsWithoutErrorMessage.includes(notification.template)) {
        this.toastr.error('The content cannot be displayed - this workflow version was deleted');
      } else {
        this.goToMyTasks(notification);
      }
    } else {
      if (goToStep) {
        this.router.navigateByUrl(this.helperService.getStepUrlFromNotification(
          notification.data.link, notification.data?.link?.is_template, notification.data.step_uuid
        ));
      } else {
        if (notification.template === NotificationTemplate.ReviewRequestSentForReviewToReviewer) {
            //  Check if the a task is still active for that document (if the user has access to it)
            this.tasksService.getTasks(0, Number.MAX_SAFE_INTEGER).pipe(
              map(tasks => tasks.data)
            ).subscribe(tasks => {
              tasks = tasks.filter(task => task.task_type === 'review');
              const taskExists = tasks.find(
                task => task.original_version_uuid === notification.data?.link?.parameters?.original_version_uuid &&
                  task.original_workflow_uuid === notification.data?.link?.parameters?.original_workflow_uuid);

              if (!taskExists) {
                this.toastr.warning('Your review task has been canceled or is done.');
              }

              this.router.navigate([this.helperService.getWorkflowUrlFromNotification(
                notification.data.link, notification.data?.link?.is_template, notification.data.step_uuid
              )]);
            });
        } else if (notification.template === NotificationTemplate.WorkflowAccessListRequested) {
          const accessTab = notification.data.access_level === TemplateAccessRequestType.ACCESS
                            ? { typeAccess: true }
                            : { typeWorkspace: true };
          this.router.navigate([this.helperService.getWorkflowUrlFromNotification(
            notification.data.link, notification.data?.link?.is_template, notification.data.step_uuid
          )], { queryParams: accessTab });
        } else if (notification.template === NotificationTemplate.WorkflowAccessListApproved) {
          this.router.navigate([this.helperService.getWorkflowUrlFromNotification(
            notification.data.link, notification.data?.link?.is_template, notification.data.step_uuid
          )]);
        } else {
          this.router.navigate([this.helperService.getWorkflowUrlFromNotification(
            notification.data.link, notification.data?.link?.is_template, notification.data.step_uuid
          )]);
        }
      }
    }
  }

  goToPackage(notification: NotificationResponse, isLocal: boolean = false): void {
    this.markAsRead(notification);
    const packageId = notification.data.link?.parameters?.package_uuid;
    const packageVersionId = notification.data.link?.parameters?.package_version_uuid;
    if (!packageId) {
      this.router.navigate([this.routingService.NOT_FOUND.url()]);
    } else {
      this.router.navigate([this.routingService.MARKETPLACE_INFO.url([packageId, packageVersionId])], { queryParams: { isLocal } });
    }
  }

  goToPrivateMarketplace(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.MARKETPLACE_PRIVATE.url()]);
  }

  goToParentChildCompanies(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.COMPANY_ADMIN_INFORMATION_PARENT_CHILD_COMPANIES.url()]);
  }

  goToCompanySystems(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.COMPANY_ADMIN_SYSTEMS.url()]);
  }

  goToLicence(notification: NotificationResponse): void {
    this.markAsRead(notification);
    if (this.store.selectSnapshot(UserSelectors.isBTRAdmin)) {
      const companyId = notification.data.company?.uuid;
      this.router.navigate([this.routingService.COMPANIES_COMPANY_LICENSES.url([companyId])]);
    } else {
      this.router.navigate([this.routingService.COMPANY_LICENSES.url()]);
    }
  }

  goToCompanyUsers(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.COMPANY_USERS.url()]);
  }

  goToProfilePage(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.PROFILE_BASIC_INFORMATION.url()]);
  }

  goToSettingsPage(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.COMPANY_ADMIN_GENERAL_SETTINGS.url()]);
  }

  goToWorkflowsList(): void {
    this.router.navigate([this.routingService.WORKFLOWS.url()]);
  }

  goToTemplatesList(): void {
    this.router.navigate([this.routingService.TEMPLATES.url()]);
  }

  goToReportPage(notification: NotificationResponse): void {
    this.markAsRead(notification);
    this.router.navigate([this.routingService.REPORTS_KEY.url([notification.data.report_link])]);
  }

  markAsRead(notification: NotificationResponse): void {
    if (notification.is_read) {
      return;
    }
    this.notificationsService.markAsRead(notification.uuid)
      .pipe(filter(result => !!result))
      .subscribe(result => {
        this.store.dispatch(new MarkNotificationAsRead(notification.uuid));
      });
  }
}
