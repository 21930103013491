import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IApprovalOrReviewRequestSeriesUser, ISignatureData } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DocumentAssignmentsService {
  private path = '/api/document-assignments';

  constructor(private apiService: ApiService) {}

  public getAssignmentsList(workflowUuid: string, assignmentType: string) {
    return this.apiService.get(`${this.path}/list/${workflowUuid}/${assignmentType}`);
  }

  public saveAssignmentsList(workflowUuid: string, assignmentType: string, series: IApprovalOrReviewRequestSeriesUser[][]) {
    return this.apiService.post(`${this.path}/list/${workflowUuid}/${assignmentType}`, {series});
  }
}
