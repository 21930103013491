import { DocumentNamingPatternItems } from '@core/constants';
import {
  IApprovalOrReviewRequest,
  IApprovalOrReviewRequestSeriesUser,
  IBulkSigningSignature,
  IImpactAssessmentEcmCachedResult,
  IImpactAssessmentLiteWithClassifications,
  IImpactAssessmentSdoxLink,
  IImplementationDeliverable,
  ISummaryEnvironment,
  ITestResultLink
} from '@core/interfaces';
import { ICompanyContact, IUserLite } from '@core/models';
import { BranchVariable } from '@shared/components/data-flow/core';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import { ICompanySystem } from './company-system.interface';
import { LinksList } from './trace-matrix.interface';
import { IWorkflowReviewLockResponse } from './review.interface';

// Workflow base interface. It contains common properties for both template and runtime workflow
export interface IWorkflowBase {
  author?: IUserLite;
  uuid?: string;
  gid?: string;
  version?: number;
  version_object?: IWorkflowTemplate;
  current_version?: IWorkflowTemplate;
  current_version_uuid?: string;
  is_current_version?: boolean;
  version_root_uuid?: string;
  clone_parent_uuid?: string;
  name?: string;
  owner_name?: string;
  owner_uuid?: string;
  owner_user_uuid?: string;
  system_name?: string;
  system_uuid?: string;
  site_uuid?: string;
  is_template?: boolean;
  is_published?: boolean;
  is_autosave?: boolean;
  state_parent_uuid?: string;
  display_label?: string;
  description?: string;
  type?: TemplateType;
  sdox_document_category?: SdoxDocumentCategory;
  package?: string;
  created_at?: string;
  updated_at?: string;
  system_owner_contact?: ICompanyContact;
  business_owner_contact?: ICompanyContact;
  steps?: ICanvasStep[];
  transitions?: ICanvasTransition[];
  initiator_uuid?: string;
  initiator?: IUserLite;
  system?: ICompanySystem;
  published_by?: IUserLite;
  published_at?: string;
  document_title?: string;
  tm_links_finished?: boolean;
  tm_links_updated_at?: string;
  source_document?: IWorkflowSourceDocument;
  prefix?: string;
  template_name?: string;
  template_version?: number;
  type_label?: string;
}

export interface IWorkflowSourceDocument {
  display_label: string;
  document_title: string;
  version: number;
  version_uuid: string;
  workflow_uuid: string;
  status: string;
}

interface IWorkflowRuntimeSignatureStatus {
  action_status: TCActionStatus;
  action_date: string;
}

interface IWorkflowRuntimeSignature {
  user: IUserLite;
  approval_status: IWorkflowRuntimeSignatureStatus;
}


interface IWorkflowRuntimeLinks {
  display_label: string;
  version_root_uuid: string;
  version_uuid: string;
}

interface IWorkflowRuntimeDependencies {
  document_title: string;
  uuid: string;
  version: string;
  type: TemplateType;
}

export interface IWorkflowRuntimeMetadata {
  included_in: IWorkflowRuntimeDependencies[];
  links: IWorkflowRuntimeLinks[];
  approvers: IWorkflowRuntimeSignature[];
  source_test_script_data: ISourceTsData;
}

export interface ISourceTsData {
  display_label: string;
  document_title: string;
  version_uuid: string;
  workflow_uuid: string;
  status: string;
}

export interface IRuntimeLite {
  uuid?: string;
  author?: IUserLite;
  version?: string;
  current_version_uuid?: string;
  display_label?: string;
  system?: ICompanySystem;
  status?: CanvasWorkflowStatus;
  document_title?: string;
  type?: string;
  created_at?: string;
  updated_at?: string;
  is_overdue?: boolean;
  template_name?: string;
  cached_data?: IWorkflowCachedData;
  version_root_uuid?: string;
  sdox_document_category?: string;
  has_pending_access_request?: boolean;
  type_label?: string;
}

export interface IRuntimeVersionLite {
  uuid: string;
  version: string;
  is_current_version: boolean;
  is_template: boolean;
  display_label: string;
  sdox_document_category: string;
  system_uuid: string;
  status: string;
  created_at: string;
  updated_at: string;
  published_at: string;
  author_uuid: string;
}

export interface IWorkflowCachedData {
  progress?: number;
  branch_variables?: BranchVariable[];
}

// Workflow template
export interface IWorkflowTemplate extends IWorkflowBase {
  document_settings?: ICanvasDocumentSettings;
  filter_tags?: IWorkflowTemplateFilterTag[];
  status?: CanvasWorkflowStatus;
  version_uuid?: string;
  has_pending_access_request?: boolean;
  display_label?: string;
}

// Workflow runtime
export interface IWorkflowRuntime extends IWorkflowBase {
  document_settings?: IRuntimeDocumentSettings;
  active_step_uuid?: string;
  is_solid_version?: boolean;
  filter_tags?: IWorkflowRuntimeFilterTag[];
  template_version_uuid?: string;
  template_name?: string;
  template_uuid?: string;
  status?: RuntimeWorkflowStatus;
  active_branch_step_uuids?: string[];
  cached_data?: IWorkflowCachedData;
  test_script_data?: IWorkflowTestScriptData;
  test_script_execution_data?: IWorkflowTestScriptData;
  steps_execution_data?: IRowTestScriptExecutionData[];
  ratio?: number;
  originalVersionRootUuid?: string;
  originalUuid?: string;
  status_original_document?: string;
  links_count?: number;
  metadata?: IWorkflowRuntimeMetadata;
  links?: LinksList[];
  cancel_status_data?: CancelTsExecutionResponse;
  hasMetadata?: boolean;
  hasMissingFiles?: boolean;
  is_review_session_active?: boolean;
  review_lock?: IWorkflowReviewLockResponse;
}

export interface IRowTestScriptExecutionData {
  comment: string;
  confirmation_user?: IUserLite;
  rows_requirement_id: string;
  attachments?: IAttachmentFileLink[];
  signature_date: string;
  signature_user?: IUserLite;
  table_display_title: string;
  table_order_index: number;
  updated_at: string;
  external_document: IAttachmentFileLink;
  sdox_post_implementation_link: IDeviationLink;
  sdox_pre_approval_link: IDeviationLink;
}

export interface IRowTestScriptFailWithoutDeviation {
  comment: string;
  confirmation_user?: IUserLite;
  signature_date: string;
  signature_user?: IUserLite;
  created_at?: string;
}

export interface IDeviationLink {
  workflow_uuid: string;
  display_label: string;
  document_title: string;
  version_uuid: string;
}

export interface IAttachmentFileLink {
  file_uuid: string;
  name: string;
  mime_type?: string;
  sequence_filename?: string;
}

export interface ICanvasWorkflowError {
  errorType?: string;
  message?: string;
  stepID?: string;
  step?: ICanvasStep;
  key?: string;
}

export interface ICanvasStep {
  display_title?: string;
  uuid?: string;
  gid?: string;
  fe_uuid?: string;
  fe_type?: CanvasFunctionalElementType;
  is_under_approval_or_review?: boolean;
  offsetX?: any; // The API returns string but expects number
  offsetY?: any; // The API returns string but expects number
  disabled?: boolean;
  inputs?: ICanvasFunctionalElementVariable[];
  outputs?: ICanvasFunctionalElementVariable[];
  step_data?: ICanvasStepData;
  signature_request?: IApprovalOrReviewRequest;
  config_data?: any;
  task_status?: string;
  order_index?: number;
  level?: number;
  is_approved?: boolean;
  is_completed?: boolean;
  is_current?: boolean;
  is_unreached?: boolean;
  is_changed?: boolean;
  workflow_uuid?: string;
  cached_data?: IWorkflowCachedData;
  test_result_link?: ITestResultLink;
  pdfFile?: string;
  links_count?: number;
  links?: LinksList[];
  prev_step?: string;
}

export interface IExternalFile {
  file_uuid?: string;
  original_filename?: string;
  mime_type?: string;
}

export interface ICanvasStepDataRow {
  Description: string;
  Step?: string;
  RequirementId: string;
  created_at: string;
  fe_sdox_requirements_table_uuid: string;
  links_count: number;
  ia_link?: IImpactAssessmentSdoxLink;
  order_index: number;
  row_gid: string;
  updated_at: string;
  uuid: string;
  links?: LinksList[];
  test_result_link?: ITestResultLink;
  mandatory_attachment?: boolean;
  files_count?: number;
  files: IExternalFile[];
}

export interface ICanvasStepData {
  columns?: ICanvasTableColumn[]; //{ name: string, key: string }[];
  rows?: ICanvasStepDataRow[];
  company_uuid?: string;
  links?: LinksList[];
  step_uuid?: string;
  created_at?: string;
  updated_at?: string;
  uuid?: string;
  label?: string;
  signature_request_history: IApprovalOrReviewRequest[];
  signature_request: IApprovalOrReviewRequest;
  task_status?: RuntimeWorkflowStatus;
  impact_assessment_results?: IImpactAssessmentEcmCachedResult;
  question_selected_answer?: string;
  deliverables?: IImplementationDeliverable[];
  executed_deliverables?: IImplementationDeliverable[];
  summary_reports?: IImplementationDeliverable[];
  environments?: ISummaryEnvironment[];
  change_descriptions?: IChangeDescription[];
  document_status?: string;
  review_request: IApprovalOrReviewRequest;
  review_request_history: IApprovalOrReviewRequest[];
  last_finalized_review_request?: {
    uuid: string;
    version_uuid: string;
  };
  selected_priority: string;
  intended_use?: IIntendedUse[];
  files?: IExternalFile[];
  content?: string;
  executionRequiredMessage?: string; // used by download pdf service, not used by API
  summaryRequiredMessage?: string; // used by download pdf service, not used by API,
  images?: IExternalFile[];
  imagesMap?: any;
  isDefaultImages?: boolean;
  links_count?: number;
  execution_request?: IExecutionRequest
}

export interface IExecutionRequest {
  execution_session_uuid?: string;
}

export interface ISignatureData {
  comment: string;
  series: IApprovalOrReviewRequestSeriesUser[][];
  due_date: string;
  require_all: boolean;

  notify_users: string[];
}

export interface IExecutionData {
  comment: string;
  series: IApprovalOrReviewRequestSeriesUser[][];
  due_date: string;
}

export interface IReviewData {
  comment: string;
  series: IApprovalOrReviewRequestSeriesUser[][];
  due_date: string;
  require_all: boolean;
}

export interface ICanvasFunctionalElementConfig {
  uuid: string;
  name: string;
  fe_type: CanvasFunctionalElementType;
  config_data: any;
  step_data: any;
  created_at?: string;
  updated_at?: string;
  is_new?: boolean;
}

export interface ISectionTab {
  title: string;
  fe_types: string[];
  size: number;
}

export interface ICanvasTransition {
  from_gid?: string;
  from_port_index?: number;
  to_gid?: string;
  to_port_index?: number;
  from_uuid?: string;
  to_uuid?: string;
}

export interface ICanvasFunctionalElement {
  uuid: string;
  version: string;
  icon_css: string;
  fe_type: CanvasFunctionalElementType;
  display_title: string;
  default_title: string;
  inputs: ICanvasFunctionalElementVariable[];
  outputs: ICanvasFunctionalElementVariable[];
  step_data: any;
  config_data: any;
}

export interface ICanvasFunctionalElementVariable {
  name: string;
  description: string;
  required: boolean;
  value: any;
  location: string;
  type: string;
}

export interface ICanvasTableColumn {
  key?: string;
  name?: string;
  position?: number;
  sortable?: boolean;
  type?: string;
  width?: number;
  isLocked?: boolean;
  isMandatory?: boolean;
  EditCellAfterPass?: boolean;
}

export interface ICanvasModalColumn extends ICanvasTableColumn {
  isEditing: boolean;
  isMovable?: boolean;
  isDeletable?: boolean;
  error?: string;
}

export interface ICanvasData {
  [key: string]: string;
}

export interface ICanvasField {
  uuid: string;
  field_type: string;
  display_label?: string;
  required?: boolean;
  canvas_identifier?: string;
  canvas_value?: string;
  default?: string;
  placeholder?: string;
  step_data?: any;
}

export interface ICanvasQuestion {
  uuid?: string;
  type: string;
  order_index?: 0;
  title?: string;
  validations?: string[];
  api_name?: string;
  display_label?: string;
  canvas_identifier?: string;
  answer_value?: string;
  display_question_title?: boolean;
  answers?: ICanvasAnswer[];
  expanded?: boolean;
}

export interface ICanvasAnswer {
  uuid: string;
  score: number;
  title?: string;
  display_label?: string;
  answer_value?: string;
  order_index?: number;
  is_checked?: boolean;
  placeholder?: string;
  questions?: ICanvasQuestion[];
  triggers?: ICanvasQuestion[];
  trigger?: ICanvasTrigger;
}

export interface ICanvasConfigData {
  sequence_prefix?: string;
  required_column_keys?: string[];
  priority_list?: string[];
  show_links?: boolean;
  attachment_column?: boolean;
  unchecking_mandatory_password?: boolean;
  na_option?: number;
  enable_ia?: boolean;
  question_text?: string;
  question_enable?: boolean;
  question_answer_list?: string[];
  impact_assessment_uuid?: string;
  impact_assessment?: IImpactAssessmentLiteWithClassifications;
  selected_environment?: string; // Used by Implementation
  non_sequential_execution: boolean;
}

export interface ICanvasTrigger {
  uuid?: string;
  questions: ICanvasQuestion[];
}

export interface ICanvasVersion {
  uuid: string;
  version: string;
}

export interface ICanvasDocumentSettings {
  document_naming_pattern: IDocumentNamingPattern[];
  selected_preset_name?: string;
}

export interface IRuntimeDocumentSettings {
  title?: string;
  document_naming_pattern: IDocumentNamingPattern[];
  disclaimer?: string;
  selected_preset_name?: string;
}

export interface IDocumentNamingPattern {
  label: string;
  key: keyof typeof DocumentNamingPatternItems;
  order_index: number;
  is_enabled: boolean;
  value: string;
}

export enum RuntimeWorkflowStatus {
  Draft = 'draft',
  WaitingForSignature = 'waiting_for_signature',
  Approved = 'approved',
  InReview = 'in_review',
  Reviewed = 'reviewed',
  CanceledReviewed = 'cancelled_review',
  Obsolete = 'obsolete',
  InExecution = 'in_execution',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Published = 'published',
}

export enum IWorkflowType {
  Template = 'template',
  Runtime = 'runtime'
}

export enum CanvasWorkflowStatus {
  Published = 'published',
  Draft = 'draft',
  Obsolete = 'obsolete'
}

export enum TemplateWorkflowStatusLabel {
  published = 'Published',
  draft = 'Draft',
  obsolete = 'Obsolete'
}

export enum WorkflowStatusLabel {
  published = 'Published',
  draft = 'Draft',
  waiting_for_signature = 'Waiting for Signature',
  approved = 'Approved',
  in_review = 'In Review',
  reviewed = 'Reviewed',
  obsolete = 'Obsolete',
  cancelled = 'Canceled',
  rejected = 'Rejected',
  completed = 'Completed',
  in_execution = 'In Execution'
}

export enum ILinksEntityType {
  Workflow = 'workflow',
  Step = 'step',
  Row = 'row',
  Cd = 'cd',
  Iu = 'iu',
  Element = 'element'
}

export interface ICanvasPosition {
  offsetX: number;
  offsetY: number;
}

export interface ICanvasFunctionalElementInfo {
  position: ICanvasPosition;
  type: CanvasFunctionalElementType;
}

export const defaultSequencePrefix = 'Req_ID_';
export const testScriptSequencePrefix = 'Step';


export interface IWorkflowTemplateFilterTag {
  uuid: string;
  name: string;
  is_checked?: boolean;
}

export interface IWorkflowRuntimeFilterTag {
  uuid: string;
  name: string;
  is_checked?: boolean;
  values?: IWorkflowRuntimeFilterTagValue[],
  filter_tag_uuid?: string;
  is_disabled?: boolean;
}

export interface IWorkflowRuntimeFilterTagValue {
  uuid: string;
  filter_tag_uuid: string;
  value: string;
  is_checked: boolean;
  is_hidden: boolean;
}

export interface IAdditionalQuestionChangeEvent {
  isEnabled: boolean;
  text: string;
  formIsValid?: boolean;
}

export interface IAdditionalRuntimeQuestionChangeEvent {
  answer: 'Yes' | 'No';
}

export enum TemplateType {
  SDOX = 'SDOX',
  ECM = 'ECM',
  ILM = 'ILM'
}

export enum TemplatesView {
  Listing = 'listing',
  CreateWorkflowModal = 'create-workflow-modal'
}

export enum SdoxDocumentCategory {
  General = 'general_document',
  Requirements = 'requirement_document',
  Test_Script = 'test_script',
  Test_Script_Execution = 'test_script_execution',
  Trace_Matrix = 'trace_matrix'
}

export enum SdoxDocumentCategoryLabel {
  general_document = 'General',
  requirement_document = 'Requirements',
  test_script = 'Test Script',
  test_script_execution = 'Executed Test Script',
  trace_matrix = 'Trace Matrix',
}

export interface IDocumentHeader {
  template: string;
}

export interface ICanvasSwitchCases {
  left: string;
  right: string;
  bottom: string;
  bottom_left: string;
  bottom_right: string;
}

export enum SwitchCases {
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom',
  'Bottom Left' = 'bottom_left',
  'Bottom Right' = 'bottom_right',
}

export interface IChangeDescription {
  uuid?: string;
  gid?: string;
  text?: string;
  links_count?: number;
}

export interface IIntendedUse {
  uuid?: string;
  gid?: string;
  text?: string;
  links_count?: number;
}

export enum TCActionStatus {
  Waiting = 'waiting',
  Pending = 'pending',
  Completed = 'completed',
}

export enum ApprovalActionStatusLabel {
  waiting = 'Waiting',
  pending = 'Pending',
  completed = 'Completed',
}

export enum ApprovalActionStatusUILabel {
  approved = 'Signed',
  pending = 'Pending'
}

export enum ReviewActionStatusUILabel {
  reviewed = 'Reviewed',
  pending = 'Waiting for Signature'
}

export enum ReviewListActionStatusUILabel {
  reviewed = 'Reviewed',
  pending = 'Pending'
}

export interface ICurrentTestScriptExecution {
  workflow_uuid: string;
  version_uuid: string;
  document_title: string;
  display_label?: string;
}

export interface IWorkflowTestScriptData {
  require_signature_after_each_step: boolean;
  current_test_script_execution: ICurrentTestScriptExecution;
}

export interface IBulkSigningDialogResult {
  signedData: IBulkSigningResult;
  currentTable: ICanvasStepData;
}

export interface IBulkSigningResult {
  row_uuids: string[];
  signature_user: IUserLite;
  confirmation_user: IUserLite;
  updated_at: string;
  signature_date: string;
}

export interface LinksEntitySourceData {
  fromEntityType: ILinksEntityType;
  fromEntityId: string;
  row_gid?: string;
  gid?: string; // for Change Description
  document_uuid?: string;
}

export interface ICreateWorkflowRuntimeRequest {
  template_version_uuid?: string;
  document_title: string;
  prefix: string;
  system_uuid: string;
  department_uuid: string;
  site_uuid: string;
}

export interface CancelTsExecutionResponse {
  comment: string;
  created_at: string;
  created_by: IUserLite;
}

export interface ISignedDetails {
  comment?: string;
  confirmation_user: IUserLite;
  signature_user: IUserLite;
  updated_at: string;
  signature_date: string;
  attachments?: IAttachmentFileLink[];
}

export interface IUpdateCellContentRequest {
  row_uuid: string;
  cell_key: string;
  content: string;
  signature?: IBulkSigningSignature;
}

export enum TableColumnActions {
  confirm = 'confirm',
  cancel = 'cancel',
  edit = 'edit',
  delete = 'delete',
  confirmDeletion = 'confirmDeletion',
  cancelDeletion = 'cancelDeletion',
}

export interface AsyncSaveStepRequestResource {
  data: ICanvasStep;
  import_type: string;
}

export interface LockStatus {
  is_importing_locked: boolean;
  fe_type: CanvasFunctionalElementType;
  display_title: string;
}

export interface ITemplateListingDocumentSettings {
  document_settings: IRuntimeDocumentSettings;
  prefix: string;
  uuid: string;
}

export interface IUpdateFilterTagRequest {
  filter_tag_uuid: string;
  filter_tag_value_uuid: string;
  enabled: boolean;
}

export interface CancelWorkflowRuntimeRequest {
  reason: string;
  signature: IBulkSigningSignature
}