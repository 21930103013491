import {
  ChangeDetectionStrategy, Component,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { ITask, ITaskFilter } from '@core/interfaces';
import {
  HelperService, RoutingService
} from '@core/services';
import { Store } from '@ngxs/store';
import { MarkTaskAsSeen } from '@store/tasks/tasks.actions';
import { UserSelectors } from '@store/user/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCardComponent {
  @Input() tasks: ITaskFilter;

  constructor(
    private router: Router,
    private routingService: RoutingService,
    private helperService: HelperService,
    private store: Store,
    private toastr: ToastrService
  ) { }

  goToWorkflow(task: ITask): void {
    this.store.dispatch(new MarkTaskAsSeen(task.uuid)).pipe(take(1)).subscribe(() => {
      this.router.navigate([this.helperService.getWorkflowUrlFromTask(task)]);
    }, err => this.helperService.showErrorMessage(err));
  }

  goToTasks(): void {
    this.router.navigate([this.routingService.MY_TASKS.url()]);
  }
}
