import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

export interface GetConfigResponse {
  copyright: string; // webviewerKey
  copyright2: string; // syncfusionKey
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  readonly configPath = '/api/config';

  constructor(private apiService: ApiService) { }

  getConfig(): Observable<GetConfigResponse> {
    return this.apiService.get(`${this.configPath}/`);
  }
}
